import { useState, useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

export const useKeyboardVisibility = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const isMobile = useBreakpointValue({ base: true, xl: false });

  useEffect(() => {
    if (!isMobile || !window.visualViewport) return;

    const detectKeyboard = () => {
      const isVisible = window.visualViewport.height < window.innerHeight;
      setIsKeyboardVisible(isVisible);

      if (isVisible) {
        const keyboardSpace = window.innerHeight - window.visualViewport.height;
        setKeyboardHeight(Math.max(keyboardSpace - 1, 0));
      } else {
        setKeyboardHeight(0);
      }
    };

    window.visualViewport.addEventListener('resize', detectKeyboard);
    window.visualViewport.addEventListener('scroll', detectKeyboard);

    return () => {
      window.visualViewport.removeEventListener('resize', detectKeyboard);
      window.visualViewport.removeEventListener('scroll', detectKeyboard);
    };
  }, [isMobile]);

  return { isKeyboardVisible, keyboardHeight };
};
