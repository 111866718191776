import { FunctionComponent } from 'react';
import NextLink from 'next/link';
import { Box, Button, Icon, Link, useBreakpointValue, useToken } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { ArrowUpRight } from 'phosphor-react';
import { FEATURE_FLAGS } from './general';

type PayBillButtonProps = {
  title: string;
};

export const PayBillButton: FunctionComponent<PayBillButtonProps> = ({ title }) => {
  const { activeWarehouse, shamrockUser } = useGlobal().useUserGlobal.state;
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const neutral700 = useToken('colors', 'neutral.700');
  const neutral800 = useToken('colors', 'neutral.800');

  const gradientBorderBg = useBreakpointValue({ base: neutral800, xl: neutral700 });

  if (!activeWarehouse?.payBillUrl) {
    return null;
  }

  return (
    <Box as="li" my={{ base: 4, lg: 5 }} mx={{ base: 8, lg: 10 }}>
      <Link
        as={NextLink}
        target="_blank"
        href={activeWarehouse.payBillUrl}
        isExternal
        passHref
        _focusWithin={{
          outlineColor: 'violet.400',
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineOffset: '2px',
          boxShadow: 'none',
          borderRadius: '6px',
        }}
      >
        <Button
          width={{ base: '100%', xl: 'initial' }}
          as="a"
          cursor="pointer"
          target="_blank"
          tabIndex={1}
          size={FEATURE_FLAGS.SIDEBAR_CHANGES ? 'ds-lg' : isMobile ? 'ds-lg' : 'ds-lg'}
          bgColor={gradientBorderBg}
          variant="ds-ghost"
          rightIcon={
            <Icon
              as={ArrowUpRight}
              width={FEATURE_FLAGS.SIDEBAR_CHANGES ? '16px !important' : '20px'}
              height={FEATURE_FLAGS.SIDEBAR_CHANGES ? '16px !important' : '20px'}
            />
          }
          height={FEATURE_FLAGS.SIDEBAR_CHANGES ? 8 : 10}
          fontSize={FEATURE_FLAGS.SIDEBAR_CHANGES ? 'sm' : 'base'}
          px={FEATURE_FLAGS.SIDEBAR_CHANGES ? 3 : 4}
          _hover={{
            px: FEATURE_FLAGS.SIDEBAR_CHANGES ? 3 : 4,
            boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
          }}
        >
          {title}
        </Button>
      </Link>
    </Box>
  );
};
