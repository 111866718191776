import React from 'react';
import { HStack, Icon, VStack, Text } from '@chakra-ui/react';
import StatusBadge from 'frontastic/tastics/composable/orders/status-badge';

interface OrderStatusMessageProps {
  status: string;
  statusMessage: {
    message: string;
    icon: any;
  };
}

export const OrderStatusMessage = ({ statusMessage, status }: OrderStatusMessageProps) => {
  return (
    <VStack align="flex-start" px={{ base: 6, lg: 8 }} spacing={3}>
      <StatusBadge status={status} />
      {!!statusMessage && (
        <HStack align="flex-start" spacing={2}>
          <Icon as={statusMessage.icon} boxSize={5} color="secondary.700" mt="-3px" />
          <Text color="secondary.800" fontSize={{ base: 'xs', '2xl': 'sm' }} lineHeight="120%" whiteSpace="pre-line">
            {statusMessage.message}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};
