import { LineItem } from '@Types/cart/LineItem';
import { OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { shouldHandleCutOffDate, shouldIgnoreCutoff } from 'composable/components/product-card-v2/helpers';

type PartitionProductsByCutoffProps = {
  productsWithoutCutoff: ExtraProductData[];
  productsWithCutoff: ExtraProductData[];
};

export function partitionProductsByCutoff(
  extraProductData: ExtraProductData[],
  lineItems: (LineItem | OrderDetailLineItem)[],
  isEditOrder: boolean = false,
) {
  const { productsWithoutCutoff, productsWithCutoff } =
    extraProductData?.reduce<PartitionProductsByCutoffProps>(
      (acc, product) => {
        const cartItem = lineItems?.find((item) =>
          isEditOrder
            ? (item as OrderDetailLineItem).productNumber === product.productNumber
            : (item as LineItem).variant?.sku === product.productNumber,
        );

        const ignoreCutOff = shouldIgnoreCutoff(product, cartItem);
        const handleCutOffDate = !ignoreCutOff && shouldHandleCutOffDate(product);
        const hasCutoff = !ignoreCutOff && product.companyInfo?.data?.cutoffs?.daysOfWeek?.length > 0;
        hasCutoff && handleCutOffDate ? acc.productsWithCutoff.push(product) : acc.productsWithoutCutoff.push(product);
        return acc;
      },
      { productsWithoutCutoff: [], productsWithCutoff: [] },
    ) || {};
  return { productsWithoutCutoff, productsWithCutoff };
}
