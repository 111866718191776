import { Product } from '@Types/product/Product';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { sortProductsByLastPurchase } from 'composable/components/order-guide/utils/sort-products-by-last-purchase';
import {
  OrderGuideEditContextType,
  OrderGuideEditFields,
} from 'composable/components/order-guide-edit/components/types';
import { getPrice } from 'composable/helpers/utils/get-price';
import { format } from 'date-fns/format';
import { subDays } from 'date-fns/subDays';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import { OrderGuide } from 'helpers/services/shamrock';
import { productIsActive, productIsSpecialOrder } from 'helpers/utils/productTypeHandler';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import { mutate } from 'swr';
import { CACHE_KEY_PRODUCT_DATA } from 'frontastic/actions/shamrockApi/constants';
import {
  PartialOrderGuideProps,
  FullProduct,
  SHOP_PURCHASE_HISTORY,
  SORTS_KEYS_OG,
  TIME_FRAME_RANGE,
} from '../helpers';

type SortOptionKey = 'default' | 'asc' | 'desc' | 'priceAsc' | 'priceDesc' | 'itemsInCart' | 'recentlyPurchased';
type SortOptions = Partial<Record<SortOptionKey, string>>;

const PLACEHOLDER_IF_NOT_ATTRIBUTE = 'TBD';

const GROUPED_PRODUCTS_KEYS = {
  [OrderGuide.CategoryTemplateNumber.PRODUCT_FAMILY]: 'shamrock-standard_family_name',
  [OrderGuide.CategoryTemplateNumber.PRODUCT_LINES]: 'shamrock-standard_line_name',
  [OrderGuide.CategoryTemplateNumber.PRODUCT_PRICE_CODES]: 'shamrock-standard_group_name',
};

export const createSortOptions = (
  selectedOGId: string,
  formatMessage: (obj: { id: string }) => string,
): SortOptions => {
  const baseSortOptions: SortOptions = {
    asc: formatMessage({ id: 'order.guide.sort.option.asc' }),
    desc: formatMessage({ id: 'order.guide.sort.option.desc' }),
    priceAsc: formatMessage({ id: 'order.guide.sort.option.price.asc' }),
    priceDesc: formatMessage({ id: 'order.guide.sort.option.price.desc' }),
    itemsInCart: formatMessage({
      id: 'order.guide.sort.option.items.in.cart',
    }),
  };

  let prioritizedOptions: SortOptions;

  if (selectedOGId !== SHOP_PURCHASE_HISTORY) {
    prioritizedOptions = {
      default: formatMessage({ id: 'order.guide.sort.option.default' }),
      ...baseSortOptions,
      recentlyPurchased: formatMessage({
        id: 'order.guide.sort.option.recentlyPurchased',
      }),
    };
  } else {
    prioritizedOptions = {
      recentlyPurchased: formatMessage({
        id: 'order.guide.sort.option.purchaseHistory.default',
      }),
      ...baseSortOptions,
    };
  }

  return prioritizedOptions;
};

export const formatOrderGuides = (result: Array<OrderGuide.Response>): OrderGuide.FormattedOrderGuide[] => {
  return result
    .map((orderGuide) => ({ id: orderGuide.orderGuideNumber, value: orderGuide.name }))
    .sort((a, b) => {
      if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
      if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
      return 0;
    });
};

export const isValidObject = (object: any) =>
  !some(object, (value) => {
    return isNil(value) || value === '';
  });

export const formatNumberWithComma = (number: number | string): string => {
  const numberToFormat = typeof number === 'string' ? number : number.toString();
  return numberToFormat.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const sortbyPriceAsc = (
  productA: { ctInformation: Product; extraInformation: ExtraProductData },
  productB: { ctInformation: Product; extraInformation: ExtraProductData },
): number => {
  const [productAPrice, productBPrice] = getPriceForSorting(productA, productB);
  return productAPrice - productBPrice;
};

export const sortbyPriceDesc = (
  productA: { ctInformation: Product; extraInformation: ExtraProductData },
  productB: { ctInformation: Product; extraInformation: ExtraProductData },
): number => {
  const [productAPrice, productBPrice] = getPriceForSorting(productA, productB);
  return productBPrice - productAPrice;
};

export const parsePrice = (product: {
  ctInformation: Product;
  extraInformation: ExtraProductData;
}): [number, number] => {
  let productPrice;
  let productDiscountPrice;

  const priceUom = product?.ctInformation?.variants[0].attributes['shamrock-standard_price_uom'];
  if (priceUom.toLowerCase() === 'lb') {
    productPrice = product.extraInformation.pricingInfo.data.currentCasePrice ?? 0;
    productDiscountPrice = product.extraInformation.pricingInfo.data.discountedCasePrice ?? 0;
  } else {
    productPrice = product.extraInformation.pricingInfo.data.currentPrice ?? 0;
    productDiscountPrice = product.extraInformation.pricingInfo.data.discountedPrice ?? 0;
  }

  return [productPrice, productDiscountPrice];
};

export const getPriceForSorting = (
  productA: { ctInformation: Product; extraInformation: ExtraProductData },
  productB: { ctInformation: Product; extraInformation: ExtraProductData },
): [number, number] => {
  let [productAPrice, productADiscountPrice] = parsePrice(productA);
  let [productBPrice, productBDiscountPrice] = parsePrice(productB);

  productAPrice = getPrice(productAPrice, productADiscountPrice);
  productBPrice = getPrice(productBPrice, productBDiscountPrice);

  return [productAPrice, productBPrice];
};

export const sortByNameAsc = (productA: Product, productB: Product): number => {
  return productA.name.localeCompare(productB.name);
};

export const sortByNameDesc = (productA: Product, productB: Product): number => {
  return productB.name.localeCompare(productA.name);
};

export const sortbyItemsQtyInCart = (
  productA: { sku: string; qty: number },
  productB: { sku: string; qty: number },
): number => {
  return productB.qty - productA.qty;
};

const getSortedCallbackOG = (sortOption: string) => {
  return {
    [SORTS_KEYS_OG.asc]: sortByNameAsc,
    [SORTS_KEYS_OG.desc]: sortByNameDesc,
    [SORTS_KEYS_OG.priceAsc]: sortbyPriceAsc,
    [SORTS_KEYS_OG.priceDesc]: sortbyPriceDesc,
    [SORTS_KEYS_OG.itemsInCart]: sortbyItemsQtyInCart,
    [SORTS_KEYS_OG.recentlyPurchased]: sortProductsByLastPurchase,
  }[sortOption];
};

export const sortProduct = (
  data: ExtraProductData[] | Product[] | FullProduct[] | { sku: string; qty: number }[],
  sortOption: string,
) => {
  return [...data].sort(getSortedCallbackOG(sortOption));
};

export const combineProductData = (
  productArray: string[],
  productsDataCT: Product[],
  extraProductData: ExtraProductData[],
  isEditPage?: boolean,
): FullProduct[] => {
  const combinedData: FullProduct[] = productArray.reduce((acc, sku) => {
    const ctInformation = productsDataCT.find((product) => product?.slug === sku);
    const extraInformation = extraProductData.find((product) => product?.productNumber === sku);

    let productIsValid = !!ctInformation && !!extraInformation;

    if (!isEditPage) {
      productIsValid = productIsValid && productIsActive(extraInformation) && !productIsSpecialOrder(extraInformation);
    }

    if (productIsValid) {
      acc.push({
        ctInformation,
        extraInformation,
      });
    }
    return acc;
  }, []);
  return combinedData;
};

export const getUrlForOrderGuideTemplateCategory = ({
  categoryTemplateNumber,
  warehouseNumber,
  businessUnit,
  businessSegment,
}: PartialOrderGuideProps & { categoryTemplateNumber: string }) => {
  const url = `${SHAMROCK_API_URL}/order-guides/template-categories`;
  const queryParams = new URLSearchParams({
    'category-template-number': categoryTemplateNumber,
    'warehouse-number': warehouseNumber,
    'business-unit-name': businessUnit,
    'business-segment-name': businessSegment,
  });

  return `${url}?${queryParams.toString()}`;
};

export const calculateNumberOfDays = (startDateRange: keyof typeof TIME_FRAME_RANGE): string => {
  const currentDate = new Date();
  const daysToSubtract = TIME_FRAME_RANGE[startDateRange].days;
  const resultDate = subDays(currentDate, daysToSubtract);
  return format(resultDate, 'yyyy-MM-dd');
};

export const getUrlOrderGuide = (
  { customerNumber, warehouseNumber, businessUnit, businessSegment }: PartialOrderGuideProps,
  // OGM: Order Guide Management
  // OGMWP: Order Guide Management With Products
  page: 'OGM' | 'OGE' | 'OG' | 'OGPH' | 'OGMWP',
  orderGuideNumber?: string,
  startDateRange?: string,
) => {
  const baseUrl = `${SHAMROCK_API_URL}/order-guides`;
  const queryParams = new URLSearchParams({
    'customer-number': customerNumber,
    'warehouse-number': warehouseNumber,
    'business-unit-name': businessUnit,
    'business-segment-name': businessSegment,
  });
  let extraParams = new URLSearchParams({});

  if (page === 'OGPH') {
    const numberOfDays = calculateNumberOfDays(startDateRange as keyof typeof TIME_FRAME_RANGE);

    extraParams = new URLSearchParams({
      'start-date': String(numberOfDays),
      'include-pricing': 'true',
      'include-inventory': 'true',
      'include-company': 'true',
      'include-next-due-deliveries': 'true',
      'include-customer-history': 'true',
      'include-last-purchase': 'true',
    });

    return `${SHAMROCK_API_URL}/products/purchase-history?${queryParams.toString()}&${extraParams.toString()}`;
  }

  if (['OGM', 'OGMWP'].includes(page)) {
    // we do not need products data in OGM page
    extraParams = new URLSearchParams({
      'include-products': page === 'OGMWP' ? 'true' : 'false',
      //This param is used to get the categories of the products in order to make a correct copy of the order guide
      'products-include-categories': page === 'OGMWP' ? 'true' : 'false',
      'products-include-pricing': 'false',
      'products-include-inventory': 'false',
      'products-include-next-due-deliveries': 'false',
      'products-include-last-purchase': 'true',
    });
  } else {
    // page OGE and OG
    extraParams = new URLSearchParams({
      'products-include-pricing': 'true',
      'products-include-inventory': 'true',
      'products-include-next-due-deliveries': 'true',
      'include-drafts': page === 'OGE' ? 'true' : 'false',
      'products-include-company': 'true',
      'products-include-substitutions': page === 'OGE' ? 'false' : 'true',
      'products-include-last-purchase': 'true',
      'products-include-order-limits': 'true',
    });
  }

  return orderGuideNumber
    ? `${baseUrl}/${orderGuideNumber}?${queryParams.toString()}&${extraParams.toString()}`
    : `${baseUrl}?${queryParams}&${extraParams.toString()}`;
};

export const saveExtraDataFromOG = (extraProductData: ExtraProductData[]): Promise<void> => {
  return new Promise(() => {
    extraProductData?.forEach((product) => {
      mutate(
        `${CACHE_KEY_PRODUCT_DATA}${product.productNumber}`,
        { product, updated: new Date() },
        { revalidate: false },
      );
    });
  });
};

export const fetchOGTitleId = (selectedOGId: string) => {
  if (selectedOGId === SHOP_PURCHASE_HISTORY) {
    return 'order.guide.option.shopPurchaseHistory.title';
  }
  return 'order.guide.header.title';
};

export const getCustomFieldFromDynamicEdits = (
  field: keyof OrderGuideEditFields,
  sku: string,
  dynamicEdits: OrderGuideEditContextType['dynamicEdits'],
  prevValue: string | number,
) => {
  if (!dynamicEdits) {
    return null;
  }
  const newestValue = dynamicEdits[sku]?.[field];

  if (!prevValue && !newestValue) {
    return null;
  }

  // Compare for changes
  if (prevValue !== newestValue && newestValue !== undefined) {
    return newestValue;
  }

  return prevValue;
};

export const mapProductsInformationToProductsToAdd = (
  productsInformation: FullProduct[],
  dynamicEdits?: OrderGuideEditContextType['dynamicEdits'],
): OrderGuide.Product[] => {
  return productsInformation.map(({ extraInformation }) => {
    const sku = extraInformation.productNumber;
    const parComment = extraInformation.parComment;
    const parQuantity = extraInformation.parQuantity;

    return {
      productNumber: extraInformation?.productNumber,
      sequenceNumber: extraInformation?.sequence ?? 0,
      par: getCustomFieldFromDynamicEdits('parQuantity', sku, dynamicEdits, parQuantity) ?? parQuantity,
      isVisible: extraInformation.isVisible ?? true,
      comments: getCustomFieldFromDynamicEdits('parComment', sku, dynamicEdits, parComment) ?? parComment,
      majorCategoryNumber: extraInformation?.majorCategory?.categoryNumber ?? null,
      subCategoryNumber: extraInformation?.subCategory?.categoryNumber ?? null,
    };
  });
};

export const getGroupedProducts = (
  selectedOrderGuide: string,
  productsInformation: FullProduct[],
  groupingOption: string,
) => {
  if (
    selectedOrderGuide === SHOP_PURCHASE_HISTORY ||
    productsInformation?.length === 0 ||
    OrderGuide.CategoryTemplateNumber.NONE === groupingOption
  ) {
    return productsInformation;
  }

  const entries = Object.entries(OrderGuide.CategoryTemplateNumber);

  //eslint-disable-next-line
  const [_, category] = entries?.find(([_, value]) => value === groupingOption) || []; // Provide a default value in case find returns undefined

  if (!category) {
    return productsInformation;
  }

  const key = GROUPED_PRODUCTS_KEYS[category];

  return productsInformation.reduce((acc, curr) => {
    const attributes = curr.ctInformation?.variants?.[0]?.attributes;

    if (key) {
      const valueKey = attributes[key] || PLACEHOLDER_IF_NOT_ATTRIBUTE;
      acc[valueKey] = (acc[valueKey] || []) as Array<any>;
      acc[valueKey].push(curr);
    }

    // This is only for product family/line
    if (category === OrderGuide.CategoryTemplateNumber.PRODUCT_FAMILY_LINES) {
      const productFamily =
        attributes[GROUPED_PRODUCTS_KEYS[OrderGuide.CategoryTemplateNumber.PRODUCT_FAMILY]] ||
        PLACEHOLDER_IF_NOT_ATTRIBUTE;

      const productLine =
        attributes[GROUPED_PRODUCTS_KEYS[OrderGuide.CategoryTemplateNumber.PRODUCT_LINES]] ||
        PLACEHOLDER_IF_NOT_ATTRIBUTE;

      acc[productFamily] = { ...acc[productFamily] };
      acc[productFamily][productLine] = acc[productFamily][productLine] || [];
      acc[productFamily][productLine].push(curr);
    }

    return acc;
  }, {});
};
