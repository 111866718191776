import { SortedOrder } from '@Types/shamrockApi/Order';
import { orderStatusSortingValues } from 'composable/helpers/utils/order-status-sorting-values';
import { add } from 'date-fns/add';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';
import { startOfDay } from 'date-fns/startOfDay';
import { isReadyForPickup } from './order-status';

// This regex captures both start and end times along with the timezone
const timeRangePattern = /(\d{1,2}:\d{2} [AP]M) - (\d{1,2}:\d{2} [AP]M)/;
const datePattern = /(\d{2}\/\d{2}\/\d{4})/; // Match specific date format.

/**
 * Sort orders based on Status and time.
 * @param dateString string
 * @param timezone string
 * @returns number
 */
export const parseDate = (dateString: string, timezone: string) => {
  if (!dateString) return null; // Handle empty strings

  const timeMatch = dateString.match(timeRangePattern);
  const dateMatch = dateString.match(datePattern);

  if (timeMatch) {
    const [, startTime, endTime] = timeMatch;
    const referenceDate = format(new Date(), 'yyyy-MM-dd');
    const dateTimeString = `${referenceDate} ${endTime}`;
    const dateTimeFormat = 'yyyy-MM-dd h:mm a';
    const parsedTime = parse(dateTimeString, dateTimeFormat, new Date());

    // Determine if an adjustment for next day is needed (PM to AM rollover).
    const adjustment = startTime.includes('PM') && endTime.includes('AM') ? 1 : 0;

    // convert time on format 03:30 PM to 2024-08-29T15:30:00.000Z
    const today = startOfDay(new Date());
    const utcDate = new Date(
      Date.UTC(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        parsedTime.getHours(),
        parsedTime.getMinutes(),
        0,
      ),
    );

    return add(utcDate, { days: adjustment }).getTime();
  }

  if (dateMatch) {
    // convert date on format 08/29/2024 to 2024-08-29T00:00:00.000Z in timestamp format
    const dateString = dateMatch[1];
    const parsedDate = new Date(dateString);
    return parsedDate.setUTCHours(0, 0, 0, 0);
  }

  return null;
};

/**
 * Method to resolve tiebreak between ranges ending with the same time
 * @param dateA string
 * @param dateB string
 * @returns number[] | null
 */

function endTimeTiebreaker(dateA: string, dateB: string): number[] | null {
  const now = new Date();
  const timeMatchA = dateA.match(timeRangePattern);
  const timeMatchB = dateB.match(timeRangePattern);

  if (!timeMatchA || !timeMatchB) {
    return;
  }

  const [, startTimeA, endTimeA] = timeMatchA;
  const [, startTimeB, endTimeB] = timeMatchB;
  const format = 'h:mm a';

  const startA = parse(startTimeA, format, now).getTime();
  const endA = parse(endTimeA, format, now).getTime();
  const startB = parse(startTimeB, format, now).getTime();
  const endB = parse(endTimeB, format, now).getTime();
  if (endA === endB) {
    return [startA, startB];
  }
  return null;
}

/**
 * Sort orders based on Status and time.
 * @param orders Orders array
 * @returns Orders array sorted
 */
export const sortOrders = (orders: SortedOrder[]) => {
  return orders?.sort((a, b) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const statusA = orderStatusSortingValues(a.status);
    const statusB = orderStatusSortingValues(b.status);

    if (statusA !== statusB) {
      return statusA - statusB;
    }

    const dateA = parseDate(a.date, timezone);
    const dateB = parseDate(b.date, timezone);

    const areOrdersForPickUp = isReadyForPickup(a.status) && isReadyForPickup(b.status);

    if (dateA && areOrdersForPickUp && dateA === dateB) {
      const tiedTimes = endTimeTiebreaker(a.date, b.date);
      if (tiedTimes) {
        const [timeA, timeB] = tiedTimes;
        return timeA - timeB;
      }
    }

    if (!dateA && !dateB) {
      return 0;
    }

    if (!dateA) {
      return 1;
    }

    if (!dateB) {
      return -1;
    }

    return dateB - dateA;
  });
};
