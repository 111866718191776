import { FullProduct } from 'composable/components/order-guide/helpers';

export enum OrderGuideFilteredTags {
  JIT = 'JIT',
  SUR = 'SUR',
  XDK = 'XDK',
  DRY = 'Dry',
  FROZEN = 'Frozen',
  REFRIGERATED = 'Refrigerated',
}

export const filterOrderGuideByTags = (product: FullProduct, filteredTagOptions: OrderGuideFilteredTags[]) => {
  const { isJustInTime, isShipUponReceipt, isCrossdock } = product.extraInformation.companyInfo.data;
  const shamrockStandardStorage = product.ctInformation.variants[0].attributes['shamrock-standard_storage'] || [];

  const storageTypes = Array.isArray(shamrockStandardStorage)
    ? shamrockStandardStorage.map((item) => item.toLowerCase())
    : [];

  const conditions = [
    {
      tag: OrderGuideFilteredTags.JIT,
      isActive: filteredTagOptions.includes(OrderGuideFilteredTags.JIT),
      condition: isJustInTime,
    },
    {
      tag: OrderGuideFilteredTags.SUR,
      isActive: filteredTagOptions.includes(OrderGuideFilteredTags.SUR),
      condition: isShipUponReceipt,
    },
    {
      tag: OrderGuideFilteredTags.XDK,
      isActive: filteredTagOptions.includes(OrderGuideFilteredTags.XDK),
      condition: isCrossdock,
    },
    {
      tag: OrderGuideFilteredTags.DRY,
      isActive: filteredTagOptions.includes(OrderGuideFilteredTags.DRY),
      condition: storageTypes.includes('dry'),
    },
    {
      tag: OrderGuideFilteredTags.FROZEN,
      isActive: filteredTagOptions.includes(OrderGuideFilteredTags.FROZEN),
      condition: storageTypes.includes('frozen'),
    },
    {
      tag: OrderGuideFilteredTags.REFRIGERATED,
      isActive: filteredTagOptions.includes(OrderGuideFilteredTags.REFRIGERATED),
      condition: storageTypes.includes('refrigerated'),
    },
  ];

  return conditions.some(({ isActive, condition }) => isActive && condition) || !filteredTagOptions.length;
};
