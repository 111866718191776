import axios, { AxiosError } from 'axios';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { getHeadersForImpersonation } from 'composable/components/super-user/helpers/utils';
import { OrderGuide } from 'helpers/services/shamrock';
import { handleAxiosError } from 'helpers/services/shamrock/handleAxiosError';
import { saveExtraDataFromOG } from '.';

export const REQUEST_TIMEOUT = 60000;

export const orderGuide = async ([
  url,
  { accessToken, logout, onlyProducts = true, isDraft = false, toast, toastMessage },
]) => {
  if (!url) {
    return;
  }

  const localUrl = isDraft ? `${url}&is-draft=true` : url;
  try {
    const impersonatedHeaders = getHeadersForImpersonation();
    const { data } = await axios<OrderGuide.Response>({
      method: 'GET',
      url: localUrl,
      timeout: REQUEST_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...impersonatedHeaders,
      },
    });
    const extraInformation = data?.products?.data;
    //Although this is a promise, DO NOT wait, and make it asynchronous while the order guide finishes loading.
    saveExtraDataFromOG(extraInformation);
    if (onlyProducts) {
      return extraInformation?.length > 0 ? extraInformation : [];
    }
    return data;
  } catch (error) {
    console.error('Error API call: orderGuide', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    if (error instanceof AxiosError) {
      handleAxiosError(error, logout);
    }
  }
};

// TODO replace by generic Shamrock fetcher
export const orderGuidePurchaseHistory = async ([url, { accessToken, logout, toast, toastMessage, payload }]) => {
  if (!url) {
    return;
  }
  const impersonatedHeaders = getHeadersForImpersonation();
  try {
    const { data } = await axios<OrderGuide.Response>({
      method: 'POST',
      url,
      timeout: REQUEST_TIMEOUT,
      data: payload,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...impersonatedHeaders,
      },
    });
    const purchaseHistoryProducts = Array.isArray(data) && data.length > 0 ? data[0].products : [];
    window.LogRocket?.log(`PH list received, count: ${purchaseHistoryProducts.length}`);
    //Although this is a promise, DO NOT wait, and make it asynchronous while the order guide finishes loading.
    saveExtraDataFromOG(purchaseHistoryProducts);
    return purchaseHistoryProducts;
  } catch (error) {
    console.error('Error API call: orderGuidePurchaseHistory', error);
    window.LogRocket?.log('Error toast shown', { reason: 'Error API call: orderGuidePurchaseHistory' });
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    if (error instanceof AxiosError) {
      handleAxiosError(error, logout);
    }
  }
};
