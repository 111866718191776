import { AccordionItem, AccordionButton, Box, AccordionPanel, Text, Flex, Icon } from '@chakra-ui/react';
import { Minus, Plus } from 'phosphor-react';
import { AccordionLink } from 'frontastic/tastics/composable/mega-menu-three-levels-2';
import { ChildLink } from './child-link';
import { ChildTitleLink } from './child-title-link';

interface SideBarAccordionItemProps {
  title: string;
  accordionLink: AccordionLink['children'];
}

export const SideBarAccordionItem = ({ title, accordionLink }: SideBarAccordionItemProps) => {
  return (
    <AccordionItem
      borderColor="transparent"
      mb={3}
      _focusWithin={{
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '-2px',
        boxShadow: 'none',
        borderRadius: '2px',
      }}
    >
      {({ isExpanded }) => (
        <Flex borderRadius="8px" direction="column" bg={isExpanded ? 'rgba(31, 31, 35, 0.50)' : 'transparent'}>
          <AccordionButton
            _hover={{
              background: 'transparent',
              '& span > p': { color: 'primary.400' },
              '& svg': { color: 'primary.400' },
            }}
          >
            <Box as="span" textAlign="left">
              <Text fontWeight={700} color="white" fontSize="xs">
                {title}
              </Text>
            </Box>
            <Icon as={isExpanded ? Minus : Plus} color="white" h={3} w={3} ml={2} />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {accordionLink &&
              accordionLink.map((child, index) => (
                <Flex
                  gap={1}
                  flexWrap="wrap"
                  key={`${child?.title?.toLowerCase()?.replace(/[^a-z0-9]+/is, '_')}_${index}`}
                  className={`${child?.title?.toLowerCase()?.replace(/[^a-z0-9]+/is, '_')}_${index}`}
                >
                  {child.link && child.type !== 'no-link' && !child.content ? (
                    <Flex flexDirection="column">
                      <ChildTitleLink child={child} />
                      <Box mb={child.content ? 0 : 2} />
                    </Flex>
                  ) : (
                    <Text fontWeight={700} color="white" fontSize="xs" mb={child.content ? 0 : 2}>
                      {child.title}
                    </Text>
                  )}
                  {child.link && child.type !== 'no-link' && child.content ? (
                    <ChildLink childItem={child} />
                  ) : (
                    <Text as="p" fontWeight={400} color="white" fontSize="xs" mb={2}>
                      {child?.content}
                    </Text>
                  )}
                </Flex>
              ))}
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  );
};
