import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { UnsavedCartModal } from 'composable/components/cart/unsaved-cart-modal';
import { SearchInputProvider } from 'composable/components/search-input-context/search-input-context';
import { ACCESS_TOKEN } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { isPrintBackPage, isPublicPage } from 'composable/helpers/utils/is-public-page';
import { useOfflineDetector } from 'helpers/hooks/useOfflineDetector';
import { use_privateCartGlobal } from 'hooks/global/use_privateCartGlobal';
import { UseCartGlobalResponse } from 'hooks/global/use_privateCartGlobal/types';
import { use_privateCheckoutGlobal } from 'hooks/global/use_privateCheckoutGlobal';
import { use_privateCutoffsGlobal } from 'hooks/global/use_privateCutoffsGlobal';
import { UseCutoffsGlobalResponse } from 'hooks/global/use_privateCutoffsGlobal/types';
import { use_privateEditOrderGlobal } from 'hooks/global/use_privateEditOrderGlobal';
import { UseEditOrderGlobalProps } from 'hooks/global/use_privateEditOrderGlobal/types';
import { use_privateOrderGuideListGlobal } from 'hooks/global/use_privateOrderGuideListGlobal';
import { UseOrderGuideListGlobalResponse } from 'hooks/global/use_privateOrderGuideListGlobal/types';
import { use_privateTrendingItemsGlobal } from 'hooks/global/use_privateTrendingItemGlobal';
import { TrendingItemsGlobalType } from 'hooks/global/use_privateTrendingItemGlobal/types';
import { use_privateUserGlobal } from 'hooks/global/use_privateUserGlobal';
import { UseUserGlobalResponse } from 'hooks/global/use_privateUserGlobal/types';
import { useOGHeaderScroll } from 'hooks/useOGHeaderScroll';
import { PageProps } from 'frontastic';

type GlobalContextValues = {
  useUserGlobal: UseUserGlobalResponse;
  useCartGlobal: UseCartGlobalResponse;
  useCheckoutGlobal: ReturnType<typeof use_privateCheckoutGlobal>;
  useOrderEditGlobal: null;
  useTrendingItemsGlobal: TrendingItemsGlobalType;
  useOrderGuideListGlobal: UseOrderGuideListGlobalResponse;
  useRevalidateOGData: number;
  setUseRevalidateOGData: Dispatch<SetStateAction<number>>;
  useCutoffsGlobal: UseCutoffsGlobalResponse;
  useOgHeaderScroll: ReturnType<typeof useOGHeaderScroll>;
  isPrintBack: boolean;
  useEditOrderGlobal: UseEditOrderGlobalProps;
  isOffline: boolean;
};

const GlobalContext = createContext<GlobalContextValues>(null!);

export const useGlobal = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }

  return context;
};

export const GlobalProvider: FunctionComponent<PropsWithChildren & { pageProps?: PageProps }> = ({
  children,
  pageProps,
}) => {
  const [localStorageAccessToken] = useLocalStorage(ACCESS_TOKEN, '');
  const isPublic = isPublicPage(pageProps?.data) && !localStorageAccessToken;
  const isPrintBack = isPrintBackPage(pageProps?.data);
  const { isOffline } = useOfflineDetector();

  // Avoid fetching cart or unecessary data for public pages print back pages
  const avoidFetch = isPublic || isPrintBack;

  const useUserGlobal = use_privateUserGlobal({ isPublic: isPublic, isOffline });
  const useCartGlobal = use_privateCartGlobal({
    user: useUserGlobal.state,
    isPublicPage: avoidFetch,
    fetchAllAccountsWithCarts: useUserGlobal.fetchAllAccountsWithCarts,
    isOffline,
  });
  const useOrderEditGlobal = null;
  const useTrendingItemsGlobal = use_privateTrendingItemsGlobal(useUserGlobal.state, avoidFetch);
  const useOrderGuideListGlobal = use_privateOrderGuideListGlobal(useUserGlobal, avoidFetch);
  // state used as a trigger to revalidate OG data products and list OG
  const [useRevalidateOGData, setUseRevalidateOGData] = useState<number>(0);
  const useCutoffsGlobal = use_privateCutoffsGlobal({ user: useUserGlobal.state, isPublic: avoidFetch });
  const useEditOrderGlobal = use_privateEditOrderGlobal(useUserGlobal.state);
  const useCheckoutGlobal = use_privateCheckoutGlobal({
    userData: useUserGlobal,
    isPrintBack,
    softCutoffs: useCutoffsGlobal.softCutoffs,
    editOrder: useEditOrderGlobal,
    cartActions: {
      removeItem: useCartGlobal.removeItem,
      updateCartDetails: useCartGlobal.updateCartDetails,
      cart: useCartGlobal.cart,
      dispatchCart: useCartGlobal.dispatch,
      fetchAllCarts: useCartGlobal.fetchAllCarts,
      setDraftCart: useCartGlobal.setDraftCart,
    },
  });

  // OG Header Scroll
  const useOgHeaderScroll = useOGHeaderScroll(isOffline);

  return (
    <>
      <GlobalContext.Provider
        value={{
          useUserGlobal,
          useCartGlobal,
          useCheckoutGlobal,
          useOrderEditGlobal,
          useTrendingItemsGlobal,
          useOrderGuideListGlobal,
          useRevalidateOGData,
          setUseRevalidateOGData,
          useCutoffsGlobal,
          useOgHeaderScroll,
          isPrintBack,
          useEditOrderGlobal,
          isOffline,
        }}
      >
        <SearchInputProvider>{children}</SearchInputProvider>
      </GlobalContext.Provider>
      <UnsavedCartModal
        isOpen={useCartGlobal.showUsavedChanges}
        onClose={() => useCartGlobal.setShowUnsavedChanges(false)}
        onContinueWithoutSaving={useCartGlobal.redirectWithoutSaving}
      />
    </>
  );
};
