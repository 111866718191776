/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { algoliaInitSearchInsights } from 'composable/analytics/algolia/algolia-tracking';
import { analyticsTrackLogin } from 'composable/analytics/analytics-event-tracking';
import { APPCUES_ID } from 'composable/analytics/appcues';
import { appcuesIdentifyAndGroupUser } from 'composable/analytics/appcues/appcues-tracking';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { logrocktIdentifyUser } from 'composable/analytics/logrocket/logrocket-tracking';
import { APPLICATION_URL, TOAST_ICON } from 'composable/components/general';
import { DRAFT_CART_ID } from 'composable/components/mini-cart/helpers';
import { impersonatorIdFromStorage } from 'composable/components/super-user/helpers/utils';
import {
  ACCESS_TOKEN,
  EXPIRES_ON,
  IS_UNAUTHORIZED,
  REFRESH_ATTEMPT,
  REFRESH_TOKEN,
  SELECTED_BUSINESS_UNIT_KEY,
} from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { getAlgoliaKey } from 'composable/helpers/utils/user-utils';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { LOGIN_METHOD } from 'helpers/constants/eventTracking';
import { permission } from 'helpers/constants/permissions';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { isSlugValid, isSuperUserPage as isSuperUserPageCb } from 'helpers/slugHelpers';
import { trackShamrockUserLogin } from 'helpers/utils/trackShamrockUserLogin';
import {
  getBrowserAccessToken,
  getCustomerObject,
  getExpTimeFromAccessToken,
  getServerShamrockRedirectUrl,
  GetServerShamrockRedirectUrlParams,
  refreshAccessToken,
} from 'hooks/global/use_privateUserGlobal/utils';
import { useTrackLoginPage } from 'hooks/useTrackingLoginPage';
import { useSWRConfig } from 'swr';
import { fetchApiHub } from 'frontastic';
import {
  deleteLocalStorageValuesWithoutBu,
  login,
  getMdLogin,
  debugLogin,
  LoginResponse,
  handleLoginFailure,
  debugLogout,
} from 'frontastic/actions/account';
import { getAllCartsPerAccount } from 'frontastic/actions/cart';
import { reduceUser, userInitialState } from './reduce-user';
import { TokenStatus, UserGlobalStateActions } from './types';
import { getAdditionalAzureUrlParams, selectedAccountDetails, userLogout, validateAccessToken } from './utils';

export const ADDITIONAL_AZURE_URL_PARAMS = 'state';
export const MAX_REFRESH_ATTEMPT = 2;

export const use_privateUserGlobal = ({ isPublic, isOffline }) => {
  const [state, dispatch] = useReducer(reduceUser, userInitialState);
  const [localStorageAccessToken] = useLocalStorage(ACCESS_TOKEN, '');
  const [localStorageRefreshToken] = useLocalStorage(REFRESH_TOKEN, '');
  // const [localStorageExpiresOn] = useLocalStorage(EXPIRES_ON, '');
  const activeAccountKey = typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : '';
  const toast = useToast();
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const azureRedirectUrl = useRef('');
  const { cache } = useSWRConfig();

  if (state.ctUser && state.activeAccount) {
    algoliaInitSearchInsights({ user: state.ctUser.customer, activeAccount: state.activeAccount });
  }

  //Super User Guard
  //eslint-disable-next-line
  useEffect(() => {
    const isSuperUserPage = isSuperUserPageCb(router.asPath);
    if (state.isSuperUser && isSuperUserPage) {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }
    if (isSuperUserPage && !state.isSuperUser) {
      router.push(routes.HOME);
    }
  }, [router.asPath, state.isSuperUser]);

  // const clearStateFromUrl = useCallback(() => {
  //   if (router?.query?.state != null) {
  //     //eslint-disable-next-line
  //     const { state, ...newQuery } = router.query;
  //     router.replace({ pathname: router.pathname, query: newQuery }, undefined, { shallow: true });
  //   }
  // }, [router]);

  // const handleAuthError = useCallback(
  //   (errorKey: AuthErrorKeys) => {
  //     clearStateFromUrl();
  //     const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${errorKey}`;
  //     window.location.href = errorPageUrl;
  //     debugLogout({ errorKey, errorPageUrl });
  //   },
  //   [clearStateFromUrl],
  // );

  // const getAzureUrls = () => {
  //   // Additional params to be passed to the azure redirect url
  //   const asPath = router.asPath.split('?')?.[0] ?? routes.HOME;
  //   const params: GetServerShamrockRedirectUrlParams = { redirectTo: asPath };

  //   return getServerShamrockRedirectUrl(APPLICATION_URL, '301', '', params);
  // };

  const setAzureUrls = async () => {
    // Additional params to be passed to the azure redirect url
    const asPath = router.asPath.split('?')?.[0] ?? routes.HOME;
    const params: GetServerShamrockRedirectUrlParams = { redirectTo: asPath };

    try {
      azureRedirectUrl.current = getServerShamrockRedirectUrl(APPLICATION_URL, '301', '', params);
    } catch (error) {
      console.error('Error while getting azure urls', error);
    }
  };

  const successCallback = async (
    accessToken: string,
    clientIp: string,
    stateSuccess: any,
    trackLogin: boolean = true,
  ) => {
    if (trackLogin) {
      trackShamrockUserLogin({ accessToken, clientIp });
      analyticsTrackLogin({ method: LOGIN_METHOD, customerNumber: activeAccountKey ? activeAccountKey : '' });
    }

    // Call to logrocket if logrocketid is available to identify user
    if (LOGROCKET_ID) {
      const superUserEmail = state?.isSuperUser ? state?.superUserEmail : null;
      logrocktIdentifyUser(stateSuccess.ctUser.customer, stateSuccess.activeAccount, superUserEmail);
    }

    const accessCallback = window.localStorage.getItem(ACCESS_TOKEN);

    if (accessCallback) {
      // Extract any additional parameters from the azure redirect url
      const additionalParams = getAdditionalAzureUrlParams(azureRedirectUrl.current);

      let { code, state: queryState, redirectTo } = router.query;

      // Parse the query state if present
      const queryStateParsed = !!queryState && queryState !== 'undefined' ? JSON.parse(queryState as string) : null;
      let queryRedirectTo = queryStateParsed?.redirectTo || redirectTo;

      if (!!queryRedirectTo && queryRedirectTo.includes(routes.PUBLIC_PDP)) {
        queryRedirectTo = (queryRedirectTo as string).replace(routes.PUBLIC_PDP, routes.PDP);
      }

      let redirectToValue = queryRedirectTo ? queryRedirectTo : additionalParams.redirectTo;
      redirectToValue = isSlugValid(redirectToValue) ? redirectToValue : routes.HOME;

      // Reconstruct URL to include query params if any
      const redirectUrl = new URL(redirectToValue, window.location.origin);
      if (router.query.query) {
        redirectUrl.searchParams.set('query', router.query.query as string);
      }
      if (router.query.brand) {
        redirectUrl.searchParams.set('brand', router.query.brand as string);
      }

      // Redirect only if we have the `code` param, indicating first login
      if (code) {
        router.replace(
          {
            pathname: redirectUrl.pathname,
            query: redirectUrl.searchParams.toString() ? redirectUrl.searchParams.toString() : undefined,
          },
          undefined,
          { shallow: redirectUrl.pathname === routes.HOME },
        );
      }

      setTimeout(() => {
        dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      }, 1000);

      // Delaying appcues call after login to avoid issues while resolving redirects.
      setTimeout(() => {
        // Call to appcues if appcuesid and customer information is available
        if (APPCUES_ID) {
          appcuesIdentifyAndGroupUser(
            stateSuccess.ctUser.customer,
            stateSuccess.activeAccount,
            stateSuccess.shamrockUser.user.permissions.data,
          );
        }
      }, 3000);
    }
  };

  //eslint-disable-next-line
  const logout = useCallback(
    async (hardLogout: boolean = false, message?: string, isUnauthorized?: boolean, caller?: string | object) => {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });

      const originPath = router.asPath;

      userLogout(hardLogout, { message, originPath, isUnauthorized, caller: caller || 'UseUser' });

      const localStorageAccessToken = window.localStorage.getItem(ACCESS_TOKEN);
      const localStorageRefreshToken = window.localStorage.getItem(REFRESH_TOKEN);
      localStorage.removeItem(DRAFT_CART_ID);
      const refreshAttempt = parseInt(window.localStorage.getItem(REFRESH_ATTEMPT) ?? '0');
      const shouldRefresh =
        [AuthErrorKeys.SessionExpired, AuthErrorKeys.Unauthorized].includes(message as AuthErrorKeys) &&
        refreshAttempt < MAX_REFRESH_ATTEMPT;

      // if logout did not remove the access token and refresh token
      // then it might have been refreshed in the userLogout function
      if (shouldRefresh && localStorageAccessToken && localStorageRefreshToken) {
        // we try to refresh due to unauthorized 2 times, avoiding user getting
        // stuck in the login if he is really unauthorized.

        window.localStorage.setItem(REFRESH_ATTEMPT, (refreshAttempt + 1).toString());

        await setSelectedAccount(localStorageAccessToken, state.activeAccount?.key, false, localStorageRefreshToken);

        return;
      }

      if (refreshAttempt >= MAX_REFRESH_ATTEMPT) {
        deleteLocalStorageValuesWithoutBu();
        const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${message}`;
        window.location.href = errorPageUrl;
        // handleAuthError(message as AuthErrorKeys);
        // return;
      }

      dispatch({ type: UserGlobalStateActions.LOGOUT });
    }, []
    // [handleAuthError, router, state.activeAccount?.key],
  );

  //eslint-disable-next-line
  const setSelectedAccount = async (
    accessToken: string,
    accountKey: string,
    shouldTrackLogin: boolean = true,
    refreshToken?: string,
    impersonatedUserId?: string,
  ) => {
    const impersonatorId = impersonatedUserId || impersonatorIdFromStorage();
    try {
      if (!accountKey) {
        accountKey =
          typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : activeAccountKey;
      }
      const loginResponse = await login(accessToken, accountKey, impersonatedUserId || impersonatorId);
      if (loginResponse?.isSuperUser) {
        //We use router instead window.href.location to avoid a new login call
        router.push(routes.SUPER_USER_ACCOUNTS);
        dispatch({
          type: UserGlobalStateActions.SET_IS_SUPER_USER,
          payload: { isSuperUser: true, superUserEmail: loginResponse?.superUserEmail },
        });
        return;
      }
      if (loginResponse.statusCode || loginResponse.error_code) {
        throw loginResponse;
      }
      window.localStorage.setItem(ACCESS_TOKEN, accessToken);
      window.localStorage.setItem(SELECTED_BUSINESS_UNIT_KEY, loginResponse?.selectedBuKey || accountKey);

      const customerObject = getCustomerObject(accessToken, loginResponse);

      const actionPayload = {
        accessToken: accessToken,
        refreshToken: refreshToken || localStorageRefreshToken,
        expiresOn: getExpTimeFromAccessToken(accessToken),
        shamrockUser: loginResponse.shamrockUser,
        ctUser: customerObject.customer,
        activeAccount: selectedAccountDetails(loginResponse.selectedBuKey, loginResponse.businessUnits),
        activeWarehouse: { ...loginResponse.store.details, id: loginResponse.store.result.id },
        accountList: loginResponse.businessUnits,
      };
      dispatch({
        type: UserGlobalStateActions.SET_LOGGED_AS_SUPER_USER,
        payload: { loggedAsSuperUser: loginResponse.loggedAsSuperUser },
      });
      dispatch({ type: UserGlobalStateActions.LOGIN, payload: actionPayload });
      successCallback(accessToken, loginResponse.clientIp, actionPayload, shouldTrackLogin);
    } catch (error) {
      // avoid user not found getting stuck in loading
      console.warn('##DLDebug error during login process', error);

      if (error?.message) {
        // if impersonatorId is present, we should throw the error to avoid the user getting stuck in the login
        // the error will be handled in the impersonateUser function
        if (impersonatorId) {
          throw error;
        }
        try {
          const parsedError = JSON.parse(error.message);

          if (Object.values(AuthErrorKeys).includes(parsedError?.error_code)) {
            console.warn('##DLDebug error code', parsedError?.error_code);
            await logout(false, parsedError?.error_code, undefined, 'AccountSwitch:known');
          } else {
            await logout(false, AuthErrorKeys.Unauthorized, undefined, 'AccountSwitch:unkonwn');
          }
          return;
        } catch (e) {
          console.error('Error parsing login message', e);
          await logout(false, AuthErrorKeys.Unauthorized, undefined, 'AccountSwitch:2ndCatch');
          return;
        }
      }

      // on login error send to error page
      logout(false, AuthErrorKeys.Unauthorized, undefined, 'AccountSwitch:noErrorMsg');
      // clearStateFromUrl();
      // handleAuthError(AuthErrorKeys.Unauthorized);
      return;
    }
  };

  const impersonateUser = async (accessToken: string, accountKey: string, impersonatedUserId: string) => {
    console.log('##DLDebug impersonateUser', accountKey, impersonatedUserId);
    // try {
    //The reason to handle the loading state here is because we need to wait for the user to be logged in
    //before redirecting to the home page, we se again the loader since by default the setSelectedAccount function sets it to false
    dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
    await setSelectedAccount(accessToken, accountKey, true, localStorageRefreshToken, impersonatedUserId);
    dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
    router.push(routes.HOME);
    dispatch({
      type: UserGlobalStateActions.SET_LOGGED_AS_SUPER_USER,
      payload: { loggedAsSuperUser: true },
    });
    // } catch (error) {
    //   deleteLocalStorageValuesWithoutBu();
    //   clearStateFromUrl();
    //   handleAuthError(AuthErrorKeys.Unauthorized);
    // }
  };

  //eslint-disable-next-line
  const switchAccount = useCallback(
    async (accountKey: string) => {
      if (!accountKey) {
        return;
      }

      for (const key of cache.keys()) {
        cache.delete(key);
      }
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
      localStorage.setItem('previousAccount', activeAccountKey);
      localStorage.removeItem(DRAFT_CART_ID);
      try {
        await fetchApiHub(
          '/action/account/switchAccount',
          { method: 'POST' },
          { accessToken: state.accessToken, selectedBuKey: accountKey },
        );

        await setSelectedAccount(state.accessToken, accountKey, true, '');

        await toast({
          status: 'success',
          title: formatMessage({ id: 'switchAccount.toast.success' }),
          duration: 5000,
          icon: TOAST_ICON.success,
        });
      } catch (error) {
        console.error('Error while switching account', error);
      }
    },
    [state.accessToken],
  );

  const fetchAllAccountsWithCarts = useCallback(async () => {
    try {
      const response = await getAllCartsPerAccount({
        accountList: state.accountList,
        associateId: state.ctUser?.customer?.id,
      });
      if (response) {
        dispatch({ type: UserGlobalStateActions.SET_ACCOUNT_LIST, payload: { accountList: response } });
      }
      return response;
    } catch (error) {
      console.error('GLOBAL Cart error: fetchAllAccountsCarts', error);
    }
  }, [state.accountList]);

  useEffect(() => {
    if (state.accountList.length > 1) {
      if (state.accountList[0]?.cartsQuantity === undefined) {
        fetchAllAccountsWithCarts();
      }
    }
  }, [state.accountList]);

  // Create a callback checkIfLoggedIn and export it
  //eslint-disable-next-line
  const checkIfLoggedIn = useCallback(async () => {
    const incrementalLog: any = {
      accessTokens: {
        state: (state.accessToken ?? '').substring(0, 5),
        localStorage: (localStorageAccessToken ?? '').substring(0, 5),
        url: window.location.search,
      },
    };
    const setAzureUrlsstartTime = performance.now();
    await setAzureUrls();
    // const setAzureUrlsstartTime = performance.now();
    // azureRedirectUrl.current = getAzureUrls();
    // incrementalLog.azureUrl = azureRedirectUrl.current;

    if (isPublic && !localStorageAccessToken) {
      await dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }

    // handle users redirected to redirect-from-so route
    if (router?.pathname.toLowerCase().includes(routes.REDIRECT_FROM_SO.toLowerCase())) {
      incrementalLog.redirectPage = true;
      await debugLogin(incrementalLog);
      console.warn('DLDebug Redirecting user to login because user landed on the redirection page', incrementalLog);
      await logout(false, undefined, undefined, { caller: 'RedirectFromSO', log: incrementalLog });
      return;
    }

    let loginAccessToken = ''; //state.accessToken || localStorageAccessToken;
    let loginRefreshToken = /*state.refreshToken ||*/ localStorageRefreshToken;
    let hasValidToken = false;
    let shouldTrackLogin = false;

    // This was changed to use localstorage values instead of state values
    // Since the state values are not updated in the first render
    if (
      state.accessToken &&
      validateAccessToken(state.accessToken, state.expiresOn) &&
      validateAccessToken(state.refreshToken)
    ) {
    // if (
    //   loginAccessToken &&
    //   validateAccessToken(loginAccessToken, +localStorageExpiresOn) &&
    //   validateAccessToken(loginRefreshToken)
    // ) {
      // check if accesstoken is valid
      loginAccessToken = state.accessToken;
      hasValidToken = validateAccessToken(loginAccessToken);
      incrementalLog.validStateToken = true;
    }

    const setTokenStartTime = performance.now();

    // Scenario: where user is redirected from the login page
    // check if we received a code from the query
    if (!hasValidToken) {
      const {
        accessToken,
        refreshToken,
        expiresOn,
        status,
        origin,
        incrementalLog: logFromGetTokens,
      } = await getBrowserAccessToken(window);
      incrementalLog.getToken = {
        accessToken: (accessToken ?? '').substring(0, 5),
        refreshToken: (refreshToken ?? '').substring(0, 5),
        expiresOn,
        status,
        origin,
        ...logFromGetTokens,
      };

      // if (!accessToken && status === TokenStatus.INVALID) {
      //   localStorage.removeItem(IS_UNAUTHORIZED);
      //   incrementalLog.getToken.success = false;
      //   await debugLogin(incrementalLog);
      //   // couldn't get tokens so we need to logout the user. Doing a hard logout to avoid redirection loop
      //   logout(true, undefined, undefined, { caller: 'FailedGetTokens', log: incrementalLog });
      //   return;
      // }

      if (accessToken && refreshToken && expiresOn) {
        window.localStorage.setItem(ACCESS_TOKEN, accessToken);
        window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
        // window.localStorage.setItem(EXPIRES_ON, expiresOn.toString());

        // as we're using state to set the localstorage values, they might not be
        // updated in the first run, so we should update local varibles too
        loginAccessToken = accessToken;
        loginRefreshToken = refreshToken;
        hasValidToken = validateAccessToken(loginAccessToken);
        shouldTrackLogin = true;
        incrementalLog.shouldTrackLogin = shouldTrackLogin;
      }
    }

    // Scenario: we check state and localstorage values and we don't have a valid token then we try to refresh token
    // try to refresh token if we have a refresh token in local storage
    // before trying to login, so we avoid the redirect to error page.
    if (!hasValidToken && !!localStorageRefreshToken) {
    // if (Boolean(localStorageRefreshToken) && !hasValidToken && !isOffline) {
      incrementalLog.refresh = {
        attempt: true,
      };
      await refreshAccessToken(localStorageRefreshToken, (getTokensResponse) => {
        if (validateAccessToken(getTokensResponse.access_token)) {
          incrementalLog.refresh.success = true;
          window.localStorage.setItem(ACCESS_TOKEN, getTokensResponse.access_token);
          dispatch({
            type: UserGlobalStateActions.SET_ACCESS_TOKEN,
            payload: {
              accessToken: getTokensResponse.access_token,
              refreshToken: getTokensResponse.refresh_token,
              expiresOn: getTokensResponse.expires_on,
            },
          });

          loginAccessToken = getTokensResponse.access_token;
          hasValidToken = true;
        }
      });
    }

    // check localStorage accessToken
    if (
      !hasValidToken &&
      validateAccessToken(localStorageAccessToken) &&
      validateAccessToken(localStorageRefreshToken)
    ) {
      loginAccessToken = localStorageAccessToken;
      hasValidToken = true;
      incrementalLog.validLSToken = true;
    }

    if (hasValidToken && !isOffline) {
      const accountKey =
        // sometimes it might render without the window object so we need to double check
        typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : activeAccountKey;

      // try {
      //   const loginResponse = await login(loginAccessToken, accountKey, null);
      //   incrementalLog.login = {
      //     buLength: loginResponse?.businessUnits?.length ?? 'null',
      //     su: loginResponse?.isSuperUser ?? 'null',
      //     frontasticRequestid: loginResponse?.frontasticRequestid,
      //     shamrockUserId: loginResponse?.shamrockUser?.user?.userId,
      //   };
      //   if (!loginResponse.businessUnits?.length && !loginResponse?.isSuperUser) {
      //     await debugLogin(incrementalLog);
      //     deleteLocalStorageValuesWithoutBu();
      //     clearStateFromUrl();
      //     window.location.href = `${routes.PUBLIC_ERROR_PAGE}?login=${AuthErrorKeys.UserNotFound}`;
      //     return;
      //   } else {
          await setSelectedAccount(loginAccessToken, accountKey, shouldTrackLogin, loginRefreshToken);
    //     }
    //   } catch (error) {
    //     incrementalLog.loginError = error;
    //     await debugLogin(incrementalLog);
    //     deleteLocalStorageValuesWithoutBu();
    //     handleAuthError(AuthErrorKeys.UserNotFound);
    //     return;
    //   }
    }

    // We already tried to refresh the token and we still don't have a valid token
    if (!hasValidToken) {
      console.warn('DLDebug Unable to login user, redirecting to login page', incrementalLog);
      await debugLogin(incrementalLog);
      await logout(true, undefined, undefined, { caller: 'UnableToLogin', log: incrementalLog });
      // clearStateFromUrl();
    }

    const setTokenEndTime = performance.now();
    const setAzureUrlsendTime = performance.now();
    const mdGetAzureUrls = setAzureUrlsendTime - setAzureUrlsstartTime;
    const mdGetTokens = setTokenEndTime - setTokenStartTime;
    const mdLogin = getMdLogin();
    const code = new URLSearchParams(window.location.search).get('code');

    if (code && shouldTrackLogin) {
      useTrackLoginPage({
        mdGetAzureUrls: mdGetAzureUrls,
        mdLogin: mdLogin,
        mdGetTokens: mdGetTokens,
      });
      incrementalLog.trackLogin = true;
    }
    debugLogin(incrementalLog);
  }, [
    activeAccountKey,
    isPublic,
    localStorageAccessToken,
    localStorageRefreshToken,
    /*handleAuthError,
    clearStateFromUrl,
    isOffline,*/
  ]);

  //eslint-disable-next-line
  const canViewProductCatalog = useCallback(() => {
    // If user get error on algoliaKey treat as permission denied
    if (!state.loading && state?.activeAccount?.key) {
      const algoliaKey = getAlgoliaKey(state.activeAccount, state.ctUser?.customer);

      if (!algoliaKey) {
        return false;
      }
    }

    return state.shamrockUser?.user?.permissions?.data?.includes(permission.CanViewProductCatalog) || state.loading;
  }, [state.shamrockUser?.user, state.loading, state.activeAccount?.key]);

  // login once we hit here.
  //eslint-disable-next-line
  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  return {
    state,
    dispatch,
    checkIfLoggedIn,
    logout,
    switchAccount,
    canViewProductCatalog,
    impersonateUser,
    azureRedirectUrl: azureRedirectUrl.current,
    successCallback,
    fetchAllAccountsWithCarts,
  };
};
