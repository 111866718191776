import { NextRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { analyticsTrackLogin } from 'composable/analytics/analytics-event-tracking';
import { APPCUES_ID } from 'composable/analytics/appcues';
import { appcuesIdentifyAndGroupUser } from 'composable/analytics/appcues/appcues-tracking';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { logrocktIdentifyUser } from 'composable/analytics/logrocket/logrocket-tracking';
import {
  APPLICATION_URL,
  AUTHENTICATION_SESSION_LIMIT_HOURS,
  AUTH_TOKEN_REFRESH_THRESHOLD,
  GENERIC_TOAST_ERROR_ID,
  TOAST_ICON,
} from 'composable/components/general';
import { ACCESS_TOKEN, EXPIRES_ON, REFRESH_ATTEMPT, REFRESH_TOKEN } from 'composable/helpers/constants';
import { add, fromUnixTime, isAfter, isEqual, sub } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { AuthErrorKeys, ERROR_DICTIONARY } from 'helpers/constants/auth';
import { LOGIN_METHOD } from 'helpers/constants/eventTracking';
import routes from 'helpers/constants/routes';
import { isSlugValid } from 'helpers/slugHelpers';
import { trackShamrockUserLogin } from 'helpers/utils/trackShamrockUserLogin';
import { fetchApiHub } from 'frontastic';
import { debugLogout, deleteLocalStorageValuesWithoutBu, LoginResponse } from 'frontastic/actions/account';
import { azureGetTokens, azureRefreshTokens } from 'frontastic/actions/azure/azureActions';
import { GetTokensResponse } from 'frontastic/actions/azure/types';
import { getStoreDetailsForSelectedBu } from 'frontastic/actions/customer';
import { ADDITIONAL_AZURE_URL_PARAMS, MAX_REFRESH_ATTEMPT } from '.';
import { CustomerDataProps, TokenStatus } from './types';

/**
 * Validates accessToken is a valid string and has not expired
 * for refreshToken don't add the expiration check
 *
 * @param accessToken string accessToken or refreshToken
 * @param expiration string expiration time in unix timestamp (optional)
 * @returns boolean
 */
export function validateAccessToken(accessToken?: string, expiration?: number): boolean {
  if (expiration) {
    const expirationTime = new Date(expiration * 1000);
    const currentTime = new Date();

    if (expirationTime < currentTime) {
      return false;
    }
  }

  return !!(accessToken && accessToken.length > 5);
}

//fetch selected account details
export function selectedAccountDetails(activeAccountKey, accountList = []): Partial<AsAssociateBusinessUnitResult> {
  const accountDetails = accountList.find((bu) => bu.key === activeAccountKey) || accountList[0];
  return accountDetails;
}

export function getAdditionalAzureUrlParams(redirectUrl: string): GetServerShamrockRedirectUrlParams {
  // Parse the URL and extract the query parameters
  const urlParams = new URLSearchParams(redirectUrl.split('?')[1]);

  // Get the additional url parameters and parse it as a JSON object
  const additionalParams = urlParams.get(ADDITIONAL_AZURE_URL_PARAMS);
  const additionalParamsParsed: GetServerShamrockRedirectUrlParams = JSON.parse(additionalParams);

  return additionalParamsParsed;
}

type UserLogoutParams = {
  hardLogout: boolean;
  options?: {
    message?: string;
    originPath?: string;
    isUnauthorized?: boolean;
    caller?: string | object;
  };
};

/**
 * logout user function to be used outside of react components.
 * for react compoents use useGlobal().useUserGlobal.logout
 *
 * @param hardLogout boolean true if sessions should also be erased from azure
 * @param message string error message to identify why user logout and redirect to error page
 * @returns void
 */
export async function userLogout(
  hardLogout: UserLogoutParams['hardLogout'] = false,
  options: UserLogoutParams['options'] = {},
) {
  const { message, originPath, isUnauthorized, caller } = options;
  let incrementalLog: any = {
    hardLogout,
    message,
    originPath,
    isUnauthorized,
    caller,
    trace: new Error().stack.split('\n'),
  };
  const shouldRefresh = message === AuthErrorKeys.Unauthorized || message === AuthErrorKeys.SessionExpired;
  if (shouldRefresh && !hardLogout) {
    // try to refresh token
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

    if (validateAccessToken(refreshToken)) {
      incrementalLog.refresh = {
        attempt: true,
      };
      console.info('Refreshing token for unauthorized user');
      await refreshAccessToken(
        refreshToken,
        (getTokensResponse) => {
          window.localStorage.setItem(ACCESS_TOKEN, getTokensResponse.access_token);
          window.localStorage.setItem(REFRESH_TOKEN, getTokensResponse.refresh_token);
          window.localStorage.setItem(EXPIRES_ON, getTokensResponse.expires_on.toString());
        },
        isUnauthorized,
      );

      const newAccessToken = window.localStorage.getItem(ACCESS_TOKEN);
      incrementalLog.refresh.newToken = (newAccessToken ?? '').substring(0, 5);
      if (validateAccessToken(newAccessToken)) {
        incrementalLog.refresh.success = true;
        await debugLogout(incrementalLog);
        console.info('Successfully refreshed token for unauthorized user');
        window.localStorage.setItem(ACCESS_TOKEN, newAccessToken);
        return;
      }
    }
  }

  if (hardLogout) {
    incrementalLog.hardLogout = {
      attempt: true,
    };
    try {
      await fetchApiHub('/action/account/logout', { method: 'POST' });
      // await handleLogout(originPath, message, incrementalLog);
      // return;
    } catch (error) {
      console.error('Error API call: logout', error);
      deleteLocalStorageValuesWithoutBu();
      incrementalLog.hardLogout.success = false;
      // if (message && ERROR_DICTIONARY[message]) {
      const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${message}`;
      await debugLogout(incrementalLog);
      window.location.href = errorPageUrl;
      // return;
      // }
      // incrementalLog.hardLogout.error = error;
      // handleLogout(originPath, message, incrementalLog);
      return;
    }
  }

  // directly assinging the values to local storage to avoid it only be set
  // on a state refresh
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);

  if (hardLogout) {
    // If error is included in dictionary send user to error page
    if (!!message && !!ERROR_DICTIONARY[message]) {
      const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${message}`;
      incrementalLog.hardLogout.success = true;
      await debugLogout(incrementalLog);
      window.location.href = errorPageUrl;
      return;
    }

    // otherwise, send to azure login
    deleteLocalStorageValuesWithoutBu();
    incrementalLog.hardLogout.azureLogout = true;
    await debugLogout(incrementalLog);
    window.location.href = await getServerAzureLogoutUrl(message, originPath);
    return;
  }

  let state = { redirectTo: originPath || routes.HOME };

  const loginRedirectUrl = getServerShamrockRedirectUrl(APPLICATION_URL, null, message, state);
  incrementalLog.loginRedirect = true;
  await debugLogout(incrementalLog);
  window.location.href = loginRedirectUrl;
}

// const handleLogout = async (originPath, message, incrementalLog) => {
//   deleteLocalStorageValuesWithoutBu();
//   const url = getServerAzureLogoutUrl(message, originPath);
//   incrementalLog.hardLogout.azureLogout = true;
//   await debugLogout(incrementalLog);
//   window.location.href = url;
// };

export const handleLoginSuccess = ({
  router,
  originPath,
  activeAccount,
  customerInCT,
  browserAccessToken,
  clientIp,
  permissions,
}: {
  router: NextRouter;
  originPath: string;
  activeAccount: Partial<AsAssociateBusinessUnitResult>;
  customerInCT: any;
  browserAccessToken: string;
  clientIp: string;
  permissions: string[];
}) => {
  trackShamrockUserLogin({ accessToken: browserAccessToken, clientIp });

  analyticsTrackLogin({ method: LOGIN_METHOD });

  // Call to logrocket if logrocketid is available to identify user
  if (LOGROCKET_ID) {
    logrocktIdentifyUser(customerInCT.customer, activeAccount);
  }

  if (browserAccessToken) {
    const redirectPath = originPath.toLowerCase();
    const canRedirect = isSlugValid(redirectPath);

    if (redirectPath !== routes.HOME && redirectPath !== '' && canRedirect) {
      router.replace(
        {
          pathname: redirectPath,
          query: '',
        },
        undefined,
        { shallow: false },
      );
    } else {
      // remove the ?code from the url
      const { code } = router.query;
      if (code) {
        router.replace(
          {
            pathname: routes.HOME,
            query: '',
          },
          undefined,
          { shallow: true },
        );
      }
    }
  }

  // Delaying appcues call after login to avoid issues while resolving redirects.
  setTimeout(() => {
    // Call to appcues if appcuesid and customer information is available
    if (APPCUES_ID) {
      appcuesIdentifyAndGroupUser(customerInCT.customer, activeAccount, permissions);
    }
  }, 3000);
};

export async function getBrowserAccessToken(window: Window) {
  // check if the customer is logged in,
  // if not - redirect them to shamrock login page.
  const code = new URLSearchParams(window.location.search).get('code');
  let incrementalLog: any = { code };

  if (code) {
    // call azure api to get access token from code
    const response = await azureGetTokens(code);
    if (response.success) {
      incrementalLog.getTokensSuccess = true;
      return {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expiresOn: response.expires_on,
        status: TokenStatus.VALID,
        origin: 'getToken',
        incrementalLog,
      };
    }
    incrementalLog.getTokensSuccess = false;
  }

  // If the response fails, we check if there's an access token in local storage, and if valid, we return that
  // const storageAccessToken = localStorage.getItem(ACCESS_TOKEN);
  // incrementalLog.lsToken = (storageAccessToken ?? '').substring(0, 5);
  // if (storageAccessToken) {
  //   const isTokenValid = validateAccessToken(storageAccessToken);
  //   incrementalLog.lsTokenValid = isTokenValid;
  //   if (isTokenValid && +localStorage.getItem(REFRESH_ATTEMPT) < MAX_REFRESH_ATTEMPT) {
  //     incrementalLog.lsTokenSuccess = true;
  //     return {
  //       accessToken: storageAccessToken,
  //       refreshToken: localStorage.getItem(REFRESH_TOKEN),
  //       expiresOn: getExpTimeFromAccessToken(storageAccessToken),
  //       status: TokenStatus.VALID,
  //       origin: 'localStorage',
  //       incrementalLog,
  //     };
  //   }
  //   incrementalLog.lsTokenSuccess = false;
  //   return {
  //     accessToken: null,
  //     refreshToken: null,
  //     expiresOn: null,
  //     status: TokenStatus.INVALID,
  //     origin: null,
  //     incrementalLog,
  //   };
  // }

  return {
    accessToken: null,
    refreshToken: null,
    expiresOn: null,
    status: TokenStatus.MISSING,
    origin: null,
    incrementalLog,
  };
}

/**
 * Refresh the access token before the token spires.

 * @param refreshToken azure refresh token taken on get or refresh token calls
 * @param logoutUrl azure logout url
 * @param nextTimeout time in milliseconds to check the token again
 */
export async function refreshAccessToken(
  refreshToken: string,
  cb: (tokenResponse: GetTokensResponse) => void,
  isUnauthorized: boolean = false,
) {
  const authToken = localStorage.getItem(ACCESS_TOKEN);
  const expiresUnixTime = getExpTimeFromAccessToken(authToken);
  const issuedUnixTime = getIatTimeFromAccessToken(authToken);
  const expiresOn = fromUnixTime(expiresUnixTime);
  const issuedAt = fromUnixTime(issuedUnixTime);
  const now = new Date();
  const secondsToRenew = Math.floor(AUTH_TOKEN_REFRESH_THRESHOLD / 1000);
  const refreshTime = sub(expiresOn, { seconds: secondsToRenew });
  const refreshTimeFormatted = formatInTimeZone(refreshTime, 'UTC', 'yyyy-MM-dd HH:mm:ss');
  const nowFormatted = formatInTimeZone(now, 'UTC', 'yyyy-MM-dd HH:mm:ss');
  console.log('Time to refresh again token', { timeToRefresh: refreshTimeFormatted, now: nowFormatted });
  const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?error=${AuthErrorKeys.Unauthorized}`;
  // const refreshAttempt = parseInt(window.localStorage.getItem(REFRESH_ATTEMPT) ?? '0');
  // Check if user login is past the session hours limit
  if (issuedUnixTime && issuedAt) {
    const loginLimit = add(issuedAt, { hours: AUTHENTICATION_SESSION_LIMIT_HOURS });
    const refreshAttempt = parseInt(window.localStorage.getItem(REFRESH_ATTEMPT) ?? '0');
    // if (isUnauthorized || !validateAccessToken(authToken)) {
    window.localStorage.setItem(REFRESH_ATTEMPT, (refreshAttempt + 1).toString());
    // }

    if (isAfter(now, loginLimit) || isEqual(now, loginLimit)) {
      console.info(`user session is greater than the ${AUTHENTICATION_SESSION_LIMIT_HOURS} hours limit`);
      deleteLocalStorageValuesWithoutBu();

      // redirectAfterTooManyAttempts(refreshAttempt, errorPageUrl);
      // redirect user to error page avoiding a login loop if we tried it too many times
      if (refreshAttempt > MAX_REFRESH_ATTEMPT) {
        return (window.location.href = errorPageUrl);
      }

      // soft logout allowing azure to login user with a new token if possible
      return userLogout(false, { message: AuthErrorKeys.SessionExpired, caller: 'RefreshAccessToken' });
    }
  }

  if (isAfter(now, refreshTime)) {
    const res = await azureRefreshTokens(refreshToken);
    if (res.success) {
      // refresh token was successful, reset the refresh attempt
      window.localStorage.setItem(REFRESH_ATTEMPT, '0');
      localStorage.setItem(ACCESS_TOKEN, res.access_token);
      localStorage.setItem(REFRESH_TOKEN, res.refresh_token);
      // localStorage.setItem(EXPIRES_ON, res.expires_on.toString());

      cb(res);
      return;
    }
  }

  // if token expired already and refresh was not successful,
  // logout user to avoid idle session
  if (isAfter(now, expiresOn) || isEqual(now, expiresOn)) {
    // redirect user to azure logout
    deleteLocalStorageValuesWithoutBu();
    // userLogout(true, { message: AuthErrorKeys.SessionExpired, caller: 'RefreshAccessTokenFailed' });
    window.location.href = errorPageUrl;
    return;
  }
  // redirectAfterTooManyAttempts(refreshAttempt, errorPageUrl);
}

/**
 * Returns the URL to redirect the user to the Shamrock login page.
 * @param origin
 * @param errorCode
 * @returns string URL to login
 */
export const getShamrockRedirectUrl = (baseAzureRedirectUrl: string, errorCode?: string, errorMessage?: string) => {
  const origin = APPLICATION_URL;
  const parameters = new URLSearchParams({
    redirect_uri: origin,
    nonce: new Date().getTime().toString(),
  });

  if (errorCode) {
    parameters.append('error', errorCode);
  }

  if (errorMessage) {
    parameters.append('error', errorMessage);
  }

  return `${baseAzureRedirectUrl}&${parameters.toString()}`;
};

export const getAzureLogoutUrl = (baseAzureLogoutUrl: string, baseAzureRedirectUrl: string, errorCode?: string) => {
  // This function will return shamrock azure logout page URL
  // Since we use Shamrock to fetch the accessToken - we are login in on their Azure backend
  // So we will have to logout on the Azure backend
  const parameters = new URLSearchParams({
    redirect_uri: getShamrockRedirectUrl(baseAzureRedirectUrl, errorCode),
    error: errorCode ?? 'invalid_token',
  });
  return `${baseAzureLogoutUrl}&${parameters.toString()}`;
};

export const getTokenDetails = (accessTokenValue: string) => {
  // In the JSON Web Token (JWT) standard, the "kid" (key ID) claim is a string that indicates the key that was used to digitally sign the JWT.
  // This is used to verify the au  thenticity and integrity of the JWT, and to prevent JWT token abuse.
  const splitToken = accessTokenValue.split('.');
  const headerObj = JSON.parse(atob(splitToken[0]));
  const expiryDate = JSON.parse(atob(splitToken[1])).exp * 1000;
  return {
    kid: headerObj.kid,
    expiryDate: expiryDate,
  };
};

export const initialUserDetails = {
  accessToken: '',
  userId: '',
  customer: {
    customer: {},
    businessUnits: [],
  },
  customerPermissionsFromShamrock: [],
};

export const WarehouseInitialData = { payBillUrl: '', businessUnit: '', businessSegment: '', warehouseNumber: '' };

const checkIfApiFailed = async (status: number = 200, clearAllDataAndLogOut: () => {}) => {
  if (status == 401 || status == 403) {
    return await clearAllDataAndLogOut();
  }
  return;
};

export const fetchBuStoreDetails = async (
  buStoreKey: string,
  selectedBusinessUnitKey: string,
  clearAllDataAndLogOut: () => {},
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const storeDetails = await getStoreDetailsForSelectedBu(buStoreKey, selectedBusinessUnitKey, toast, toastMessage);
    await checkIfApiFailed(storeDetails?.status, clearAllDataAndLogOut);

    if (storeDetails?.businessSegment !== '') {
      return storeDetails;
    } else {
      throw '"storeIds info" not received from getStoreDetailsForSelectedBuAPI. Maybe an issue in the accessToken. Please try logging in Incognito mode or another browser';
    }
  } catch (error) {
    console.error('Error API: fetchBuStoreDetails', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    await clearAllDataAndLogOut();
  }
};

export const selectTheAssociatedBuObjectFromBusinessUnits = (
  businessUnits: Partial<AsAssociateBusinessUnitResult>[],
  selectedBusinessUnitKey: string,
) => {
  return businessUnits?.find((businessUnit) => {
    return businessUnit['key'] == selectedBusinessUnitKey;
  });
};

export const getCustomerObject = (accessToken: string, loginResponse: LoginResponse): CustomerDataProps => {
  const { shamrockUser, commercetoolsUser, businessUnits } = loginResponse;
  return {
    accessToken: accessToken,
    userId: shamrockUser.user.userId,
    customer: {
      customer: commercetoolsUser,
      businessUnits: businessUnits,
    },
    customerPermissionsFromShamrock: shamrockUser.user.permissions.data,
  };
};

/**
 * Check localstorage accessToken and if the expiration time is still valid.
 * @returns Boolean - true if the user is logged in, false otherwise
 */
export const isLoggedIn = (): Boolean => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
  const expiresOn = getExpTimeFromAccessToken(accessToken);

  if (accessToken && expiresOn > 0) {
    const expirationTime = fromUnixTime(expiresOn);

    if (isAfter(expirationTime, new Date())) {
      return true;
    } else {
      // We have an expired access token, to avoid any issue we clear the data
      deleteLocalStorageValuesWithoutBu();
    }
  }

  return false;
};

/**
 * get expiration time from a jwt access token
 */
export const getExpTimeFromAccessToken = (accessToken: string) => {
  if (!accessToken) {
    return '';
  }
  try {
    const jwtData = JSON.parse(atob(accessToken.split('.')[1]));
    return jwtData?.exp;
  } catch (error) {
    console.error('failed to parse accessToken');
    return '';
  }
};

/**
 * get the time the accessToken was issued.
 * @param accessToken
 * @returns number iat time
 */
export const getIatTimeFromAccessToken = (accessToken: string) => {
  if (!accessToken) {
    return '';
  }
  try {
    const jwtData = JSON.parse(atob(accessToken.split('.')[1]));
    return jwtData?.iat;
  } catch (error) {
    console.error('failed to parse accessToken');
    return '';
  }
};

/**
 * Returns the URL to redirect the user to the Shamrock login page.
 * Called on server functions to avoid server timeout
 * Legacy function that still uses frontend env vars
 * @param errorCode
 * @returns string URL to login
 */

// Add more fields here as needed
export interface GetServerShamrockRedirectUrlParams {
  redirectTo: string;
}

export const getServerShamrockRedirectUrl = (
  origin: string,
  errorCode?: string,
  errorMessage?: string,
  params?: GetServerShamrockRedirectUrlParams,
) => {
  const shamrockUrl = new URL(process.env.NEXT_PUBLIC_SHAMROCK_REDIRECT_URL);

  // stringify the params object to pass it as a query param
  const paramsAsString = JSON.stringify(params);

  const parameters = new URLSearchParams({
    response_type: 'code',
    client_id: process.env.NEXT_PUBLIC_SHAMROCK_CLIENT_ID,
    redirect_uri: origin,
    scope: process.env.NEXT_PUBLIC_SHAMROCK_REDIRECT_URL_SCOPE,
    nonce: new Date().getTime().toString(),
    [ADDITIONAL_AZURE_URL_PARAMS]: paramsAsString,
  });

  if (errorCode) {
    parameters.append('error', errorCode);
  }

  if (errorMessage) {
    parameters.append('error', errorMessage);
  }

  shamrockUrl.search = parameters.toString();

  return shamrockUrl.toString();
};

/**
 * Finish user Azure session and redirect to Shamrock login page.
 * Called on server functions to avoid server timeout
 * Legacy function that still uses frontend env vars
 * @param errorCode
 * @returns string URL to login
 */
export const getServerAzureLogoutUrl = (errorCode?: string, originPath?: string) => {
  // This function will return shamrock azure logout page URL
  // Since we use Shamrock to fetch the accessToken - we are login in on their Azure backend
  // So we will have to logout on the Azure backend
  const AzureUrl = new URL(process.env.NEXT_PUBLIC_SHAMROCK_AZURE_LOGOUT);
  const state = { redirectTo: originPath ?? routes.HOME };
  const parameters = new URLSearchParams({
    redirect_uri: getServerShamrockRedirectUrl(APPLICATION_URL, errorCode, null, state),
    error: errorCode ?? 'invalid_token',
  });
  AzureUrl.search = parameters.toString();
  return AzureUrl.toString();
};

/**
 * Helper to redirect to error Page when too many refresh attempts
 * @param attempt {number}
 * @param errorUrl {string}
 * @returns void
 */
// function redirectAfterTooManyAttempts(attempt: number, errorUrl: string) {
//   // redirect user to error page avoiding a login loop if we tried it too many times
//   if (attempt > MAX_REFRESH_ATTEMPT) {
//     return (window.location.href = errorUrl);
//   }
// }
