import { KeyboardEvent, memo, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  FormControl,
  FormControlProps,
  FormLabel,
  HStack,
  Icon,
  IconButtonProps,
  IconProps,
  NumberInput,
  NumberInputField,
  NumberInputFieldProps,
  ThemingProps,
  Tooltip,
  useBreakpointValue,
  useControllableState,
  UseControllableStateProps,
  useStyleConfig,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { PageVariant } from 'composable/components/types';
import { useFocusQuantityPicker } from 'composable/helpers/hooks';
import {
  activateInputAfterAddToCart,
  focusCurrentQuantityElement,
  focusNextQuantityElement,
  focusPreviousQuantityElement,
} from 'composable/helpers/utils/focus-element-by-id';
import { useFormat } from 'helpers/hooks/useFormat';
import { debounce } from 'lodash';
import { ListChecks, Minus, Plus, Warning } from 'phosphor-react';
import { QUANTITY_PICKER_DEBOUNCE_TIME } from './general';
import { OG_QTY_PERMANENT_STORAGE, useLocalStorageOrderGuide } from './order-guide/helpers';
import { KEYBOARD_KEYS, SEARCH_LOCAL_INPUT_ID } from './pdp/constants';
import * as Parts from './product-card-v2/parts';
import { useKeyboardVisibility } from './mini-cart/hooks/useKeyboardVisibility';

export interface QuantityPickerProps extends UseControllableStateProps<number>, ThemingProps {
  max?: number;
  min?: number;
  rootProps?: FormControlProps;
  isLoading?: boolean;
  hideLabel?: boolean;
  label?: string;
  buttonProps?: ButtonProps | FlexProps;
  qtyButtonProps?: Partial<IconButtonProps>;
  qtyIconProps?: Partial<IconProps>;
  disabled?: boolean;
  _container?: BoxProps;
  _numberInputField?: NumberInputFieldProps;
  showAddButton?: boolean;
  sku?: string;
  showAddToOrderGuide?: boolean;
  onAddToOrderGuide?: () => void;
  darkVariant?: boolean;
  numberInputFieldProps?: NumberInputFieldProps;
  addToCartProps?: ButtonProps;
  quantityPickerProps?: FlexProps;
  index: number;
  page?: string;
  component: string;
  isError?: boolean;
  errorMessage?: string;
  errorMessageInside?: boolean;
  onBlur?: () => void;
  onFocusChange?: (isFocused: boolean) => void;
  onNavigate?: (direction: 'up' | 'down') => void;
  lastProductIndex?: number;
  mappedIndex?: number | null;
  focusListKey?: string | null;
}
export interface QuantityPickerRef {
  focus: () => void;
  increment: () => void;
  decrement: () => void;
}

export enum QuantityComponent {
  CART_VARIANT = 'cart-variant',
  CHECKOUT_VARIANT = 'checkout-variant',
  ORDER_GUIDE_VARIANT = 'order-guide-variant',
  LIST_VARIANT = 'list-variant',
  CAROUSEL_VARIANT = 'list-variant',
  PDP_CAROUSEL_VARIANT_MAY_ALSO_LIKE = 'pdp-carousel-variant-may-also-like',
  PDP_CAROUSEL_VARIANT_SIMILAR_ITEMS = 'pdp-carousfel-variant-similar-items',
  PDP_CAROUSEL_VARIANT_TRENDING_ITEMS = 'pdp-carousel-variant-trending-items',
  PLP_VARIANT = 'plp-variant',
  PDP_PRICE_CLUSTER = 'product-detail-page-price-cluster',
  TEN_KEY_VARIANT = 'ten-key-variant',
  OG_PAR_VARIANT = 'og-par-variant',
}
/* eslint-disable */
const MemoizedButton = memo((props: ButtonProps) => <Button {...props} />);
const MemoizedFlex = memo((props: FlexProps) => <Flex {...props} />);
/* eslint-enable */

const ACTIVE_DELAY = 10;

export const QuantityPicker = forwardRef<QuantityPickerRef, QuantityPickerProps>((props, ref) => {
  const {
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    rootProps,
    isLoading,
    hideLabel,
    label = 'Quantity',
    buttonProps,
    qtyButtonProps,
    disabled,
    size = 'lg',
    variant = 'solid',
    _container,
    _numberInputField,
    showAddButton = false,
    sku,
    showAddToOrderGuide,
    onAddToOrderGuide,
    qtyIconProps,
    darkVariant,
    numberInputFieldProps,
    addToCartProps,
    quantityPickerProps,
    index,
    page,
    isError,
    errorMessage,
    onBlur,
    errorMessageInside = false,
    onNavigate,
    lastProductIndex,
    mappedIndex,
    focusListKey = null,
    ...rest
  } = props;
  const { formatMessage } = useFormat({ name: 'common' });

  const inputEl = useRef<HTMLInputElement>(null);
  const inputLastValue = useRef<number>(-10000);
  const wasFocused = useRef<boolean>(false);
  const { isKeyboardVisible } = useKeyboardVisibility();

  const { isEditingOrder, editCart, getOriginalLineItemFromOrder, updateEditedOrder } = useGlobal().useEditOrderGlobal;

  const isMobile = useBreakpointValue({ base: true, xl: false });

  const ogStoredState = useMemo(
    () => JSON.parse(localStorage.getItem(OG_QTY_PERMANENT_STORAGE)),
    [localStorage.getItem(OG_QTY_PERMANENT_STORAGE)],
  );
  const { isOffline } = useGlobal();

  const [value, setValue] = useControllableState(rest);
  const [tempValue, setTempValue] = useState<number>(() =>
    isOffline ? ogStoredState?.[sku]?.quantity ?? 0 : value || 0,
  );
  const [shouldForceFocus, setShouldForceFocus] = useState<boolean>(false);

  const isMinValue = tempValue === min;
  const isMaxValue = tempValue === max;

  const [showQuantityPickerOnIncrement, setShowQuantityPickerOnIncrement] = useState(false);
  const sectionId = `${QuantityComponent.ORDER_GUIDE_VARIANT}_${sku}_${index}`;
  const { isFocused } = useFocusQuantityPicker(`number-input-field_${sectionId}_${index}`);
  const { ogLocalStorageValues, setValue: setOgLocalStorageValue } = useLocalStorageOrderGuide();

  const debouncedSetValue = debounce((newValue) => setValue(newValue), QUANTITY_PICKER_DEBOUNCE_TIME);

  useEffect(() => {
    if (!isFocused && value !== tempValue) {
      setTempValue(value);
    }
  }, [isFocused, value]);

  const handleDecrement = () => {
    if (!isMinValue) {
      setTempValue((prev) => {
        const newValue = prev - 1;
        debouncedSetValue(newValue);
        return newValue;
      });
    }
    activateInputAfterAddToCart(sectionId, index, ACTIVE_DELAY);
  };

  const handleIncrement = () => {
    if (!isMaxValue) {
      setTempValue((prev) => {
        const newValue = prev + 1;
        debouncedSetValue(newValue);
        return newValue;
      });
    }
    activateInputAfterAddToCart(sectionId, index, ACTIVE_DELAY);
  };

  const handleInputChange = (newValue: string) => {
    if (newValue === '' || +newValue === 0) {
      setTempValue(min);
      return;
    }
    const quantity = Number(newValue);
    if (quantity >= min && quantity <= max) {
      setTempValue(quantity);
      debouncedSetValue(quantity);
    }
  };

  const handleInputBlur = () => {
    if (tempValue >= min && tempValue <= max) {
      setValue(tempValue);
    } else {
      setTempValue(Number(value));
    }
    setShowQuantityPickerOnIncrement(false);
    setShouldForceFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  useEffect(() => {
    if (isOffline && ogStoredState?.[sku]?.quantity !== undefined) {
      const updatedState = { ...ogStoredState };
      updatedState[sku].quantity = tempValue > 0 ? tempValue : null;
      localStorage.setItem(OG_QTY_PERMANENT_STORAGE, JSON.stringify(updatedState));
    }
  }, [tempValue, isOffline, ogStoredState?.[sku]]);

  const {
    // @ts-ignore
    _quantityPickerButton: { _icon: _quantityPickerButtonIconStyles, ..._quantityPickerButtonStyles },
    // @ts-ignore
    _numberInputField: _numberInputFieldStyles,
    // @ts-ignore
    _container: containerStyles,
  } = useStyleConfig('QuantityPicker', {
    size,
    variant,
  });

  useEffect(() => {
    if (isMobile && shouldForceFocus) {
      handleFocus();
    }
  }, [shouldForceFocus]);

  useEffect(() => {
    if (isOffline) {
      setOgLocalStorageValue('wentOffline', true);
      return;
    }
    // Only sync values after going back online, to avoid quantity flickering
    if (ogLocalStorageValues.wentOffline) {
      setTempValue(value);
    }
  }, [value, isOffline]);

  useEffect(() => {
    if (isEditingOrder && disabled && sku) {
      const originalOrder = getOriginalLineItemFromOrder(sku);
      if (originalOrder) {
        setTempValue(originalOrder.orderedQuantity);
      }
      updateEditedOrder({
        ...editCart,
        lineItems: editCart.lineItems.map((item) => {
          if (item.productNumber === sku) {
            return {
              ...item,
              orderedQuantity: originalOrder ? originalOrder.orderedQuantity : item.orderedQuantity,
            };
          }
          return item;
        }),
      });
    }
  }, [isEditingOrder, disabled, sku]);

  const isDecrementDisabled = isMinValue || isLoading || disabled;

  const isIncrementDisabled = isMaxValue || isLoading || disabled;

  const handleFocus = () => {
    inputEl?.current?.select();
  };

  const handleArrowRight = () => {
    if (!isMaxValue) {
      setTempValue((prev) => prev + 1);
    }
    activateInputAfterAddToCart(sectionId, index, ACTIVE_DELAY);
  };

  const handleArrowLeft = () => {
    if (tempValue > 0 && !isMinValue) {
      setTempValue((prev) => prev - 1);
    }
    activateInputAfterAddToCart(sectionId, index, ACTIVE_DELAY);
  };

  const handleArrowDown = () => {
    if (lastProductIndex && (lastProductIndex === index || lastProductIndex === mappedIndex)) {
      focusCurrentQuantityElement({ sectionId, index, moveScreen: true });
      return;
    }

    setValue(tempValue);
    handleFocus();

    if (value === tempValue) {
      focusNextQuantityElement({ shouldClick: true, moveScreen: true });
      onNavigate?.('down');
      return;
    }
    focusNextQuantityElement({ shouldClick: true, moveScreen: true });
    onNavigate?.('down');
  };

  const handleArrowUp = () => {
    setValue(tempValue);
    handleFocus();
    if (index > 0 && value !== tempValue) {
      focusPreviousQuantityElement({ shouldClick: true, moveScreen: true });
      onNavigate?.('up');
    }
    if (value === tempValue) {
      focusPreviousQuantityElement({ shouldClick: true, moveScreen: true });
      onNavigate?.('up');
      return;
    }
  };

  const widthNumberInput = useMemo(() => {
    if (!isError) {
      return 7;
    }
    const length = tempValue.toString().length;
    return length === 1 ? 3 : length === 2 ? 5 : '23px';
  }, [isError, tempValue?.toString().length]);

  const handleEnter = () => {
    if (lastProductIndex && (lastProductIndex === index || lastProductIndex === mappedIndex)) {
      focusCurrentQuantityElement({ sectionId, index, moveScreen: true });
      return;
    }
    if (page !== PageVariant.PDP) {
      focusNextQuantityElement({ shouldClick: true, moveScreen: true });
    }
  };

  const handleShiftEnter = () => {
    if ((mappedIndex && mappedIndex > 0) || index > 0) {
      focusPreviousQuantityElement({ shouldClick: true, moveScreen: true });
    }
  };

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputEl.current?.focus();
      // inputEl.current?.select();
    },
    increment: handleIncrement,
    decrement: handleDecrement,
  }));

  useEffect(() => {
    return () => {
      if (
        inputLastValue.current >= min &&
        inputLastValue.current <= max &&
        inputLastValue.current !== value &&
        wasFocused.current &&
        (inputLastValue.current > 0 || (inputLastValue.current === 0 && value > 0))
      ) {
        setValue(inputLastValue.current);
      }
    };
  }, []);

  useEffect(() => {
    inputLastValue.current = tempValue;
    wasFocused.current = isFocused;
  }, [tempValue, isFocused]);

  const focusInputSearch = () => {
    const searchField = document.getElementById(`${SEARCH_LOCAL_INPUT_ID}-v2`);
    searchField?.focus();
    SEARCH_LOCAL_INPUT_ID;
  };

  const dataFocusList = `${rest.component}${focusListKey ? `_${focusListKey}` : ''}`;

  return (
    <FormControl aria-label={`${label} selected is ${value}`} {...rootProps}>
      {hideLabel ? (
        <VisuallyHidden>
          <FormLabel>{label}</FormLabel>
        </VisuallyHidden>
      ) : (
        <FormLabel fontSize="sm" fontWeight="medium">
          {label}
        </FormLabel>
      )}
      {showAddButton && tempValue === 0 && !isFocused && !showQuantityPickerOnIncrement ? (
        <MemoizedButton
          id={`add-to-cart-button_${sectionId}_${index}`}
          data-focus-list={dataFocusList}
          data-focus-index={index}
          w="full"
          size="ds-xl"
          variant="ds-filled"
          onClick={() => {
            handleIncrement();
            activateInputAfterAddToCart(sectionId, index, 100);
          }}
          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
            if (e.shiftKey && e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              if (index === 0) {
                const searchField = document.getElementById(`${SEARCH_LOCAL_INPUT_ID}-v2`);
                searchField?.focus();
                return;
              }
              handleShiftEnter();
            }
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              e.stopPropagation();
              handleEnter();
            }
            if (e.key === 'ArrowDown') {
              e.preventDefault();
              e.stopPropagation();
              handleArrowDown();
            }
            if (e.key === 'ArrowUp') {
              e.preventDefault();
              e.stopPropagation();
              handleArrowUp();
            }
            if (e.key === 'ArrowRight') {
              setTempValue((prevValue) => {
                if (!isMaxValue) {
                  return prevValue + 1;
                }
                return prevValue;
              });
              activateInputAfterAddToCart(sectionId, index, ACTIVE_DELAY);
            }
            if (e.key.toLocaleLowerCase() === KEYBOARD_KEYS.Q) {
              setShowQuantityPickerOnIncrement(true);
              activateInputAfterAddToCart(sectionId, index, ACTIVE_DELAY);
            }
          }}
          onBlur={() => handleInputBlur()}
          isDisabled={isLoading || disabled}
          {...addToCartProps}
        >
          {formatMessage({ id: 'action.addToCart' })}
        </MemoizedButton>
      ) : (
        <MemoizedFlex
          alignItems="center"
          h="8"
          sx={{
            ...containerStyles,
            ..._container,
            ...(disabled ? { bg: darkVariant ? 'neutral.800' : 'neutral.100' } : {}),
            ...buttonProps,
            ...(darkVariant ? { background: 'neutral.900', borderColor: 'neutral.800' } : {}),
            ...(isError && !isLoading ? { borderColor: 'red.500', borderWidth: 2 } : {}),
            ...(isOffline && {
              boxShadow: 'inset 0 0 0 1px #f6ad23',
              borderColor: 'yellow.500',
            }),
          }}
          justifyContent="space-between"
          {...quantityPickerProps}
        >
          <Box
            as="button"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg={!isLoading ? 'greenGradient' : 'neutral.400'}
            onClick={() => {
              if (!isDecrementDisabled) {
                handleDecrement();
              }
            }}
            aria-label={formatMessage({ id: 'label.decrement' })}
            sx={{ ..._quantityPickerButtonStyles, ...qtyButtonProps }}
            _focus={{
              outline: '1px solid',
              outlineColor: 'violet.500',
              outlineOffset: '1px',
            }}
            _disabled={{ bg: 'neutral.400' }}
            _active={isDecrementDisabled ? {} : { bg: 'primary.700' }}
            cursor={isDecrementDisabled ? 'not-allowed' : 'pointer'}
            disabled={isDecrementDisabled}
          >
            <Icon
              as={Minus}
              sx={{ ..._quantityPickerButtonIconStyles, ...qtyIconProps }}
              tabIndex={-1}
              color={isDecrementDisabled ? 'neutral.700' : 'white'}
              _focus={{ outline: 'none' }}
            />
          </Box>
          <HStack gap={0} px="5px">
            <NumberInput
              min={min}
              max={max}
              value={tempValue ? String(tempValue) : 0}
              onChange={handleInputChange}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleFocus();
              }}
              width={page === PageVariant.PDP ? 12 : widthNumberInput}
              alignSelf="stretch"
            >
              <NumberInputField
                ref={inputEl}
                id={`number-input-field_${sectionId}_${index}`}
                data-focus-list={dataFocusList}
                data-focus-index={index}
                aria-label="quantity-picker"
                onBlur={handleInputBlur}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.shiftKey && e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (mappedIndex && mappedIndex === 0 && index === 0) {
                      focusInputSearch();
                      return;
                    } else if (!mappedIndex && index === 0) {
                      focusInputSearch();
                    }
                    handleShiftEnter();
                  }
                  if (!e.shiftKey && e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    handleEnter();
                  }
                  switch (e.key) {
                    case 'ArrowRight':
                      e.preventDefault();
                      e.stopPropagation();
                      handleArrowRight();
                      break;
                    case 'ArrowLeft':
                      e.preventDefault();
                      e.stopPropagation();
                      handleArrowLeft();
                      break;
                    case 'ArrowDown':
                      e.preventDefault();
                      e.stopPropagation();
                      handleArrowDown();
                      break;
                    case 'ArrowUp':
                      e.preventDefault();
                      e.stopPropagation();
                      handleArrowUp();
                      break;
                  }
                }}
                maxLength={3}
                disabled={isLoading || disabled}
                borderWidth={0}
                p={0}
                h="auto"
                sx={{
                  ..._numberInputFieldStyles,
                  ..._numberInputField,
                  ...(darkVariant ? { color: 'white' } : {}),
                  ...numberInputFieldProps,
                  ...(isError && !isLoading ? { color: darkVariant ? 'red.400' : 'red.500' } : {}),
                  fontSize: isKeyboardVisible ? '16px' : 'sm',
                  lineHeight: '150%',
                  height: 'full',
                }}
                textAlign="center"
                boxShadow="none! important"
                onFocus={handleFocus}
              />
            </NumberInput>

            {isError && errorMessageInside && (
              <Tooltip
                hasArrow
                placement="top"
                borderRadius="2px"
                color="neutral.200"
                background="neutral.900"
                label={errorMessage}
                aria-label={errorMessage}
                arrowShadowColor={'neutral.500'}
                border={`1px solid`}
                borderColor={'neutral.500'}
              >
                <Icon as={Warning} boxSize={5} color={'red.500'} aria-label={errorMessage} />
              </Tooltip>
            )}
          </HStack>
          <Box
            as="button"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg={!isLoading ? 'greenGradient' : 'neutral.400'}
            onClick={() => {
              if (!isIncrementDisabled) {
                handleIncrement();
              }
            }}
            aria-label={formatMessage({ id: 'label.increment' })}
            sx={{ ..._quantityPickerButtonStyles, ...qtyButtonProps }}
            _focus={{
              outline: '1px solid',
              outlineColor: 'violet.500',
              outlineOffset: '1px',
            }}
            _disabled={{ bg: 'neutral.400' }}
            cursor={isIncrementDisabled ? 'not-allowed' : 'pointer'}
            disabled={isIncrementDisabled}
            _active={isIncrementDisabled ? {} : { bg: 'primary.700' }}
          >
            <Icon
              as={Plus}
              sx={{ ..._quantityPickerButtonIconStyles, ...qtyIconProps }}
              color={isIncrementDisabled ? 'neutral.700' : 'white'}
              tabIndex={-1}
              _focus={{ outline: 'none' }}
            />
          </Box>
        </MemoizedFlex>
      )}
      {!!errorMessage && !errorMessageInside && (
        <Parts.ErrorMessage darkVariant mt={2} textProps={{ mt: 0.5, fontSize: { base: 'xs', md: 'sm' } }}>
          {errorMessage}
        </Parts.ErrorMessage>
      )}
      {showAddToOrderGuide && (
        <Button
          mt={3}
          size={{ base: 'ds-sm', md: 'ds-lg' }}
          variant="ds-icon-text-link-dark"
          onClick={onAddToOrderGuide}
          leftIcon={
            <Icon
              as={ListChecks}
              aria-label={formatMessage({ id: 'aria.label.icon.addToOrderGuide', values: { value: sku } })}
            />
          }
          sx={{ svg: { fill: 'white' } }}
          aria-label={formatMessage({ id: 'aria.label.addToOrderGuide', values: { value: sku } })}
        >
          {formatMessage({ id: 'action.addToOrderGuide' })}
        </Button>
      )}
    </FormControl>
  );
});

QuantityPicker.displayName = 'QuantityPicker';
