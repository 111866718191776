import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useGetOfflineBannerHeight } from 'composable/components/offline-detector/helpers/useGetOfflineBannerHeight';

export const useOGHeaderScroll = (
  isOffline: boolean,
  orderGuideHeaderRef?: MutableRefObject<HTMLDivElement> | null,
) => {
  const isScrollingDown = useRef(false);
  const lastScrollTop = useRef(0);

  const [isHideHeader, setIsHideHeader] = useState(false);
  const [isIntersectionPoint, setIsIntersectionPoint] = useState(false);
  const [siteHeaderHeight, setSiteHeaderHeight] = useState(0);
  const offlineBannerHeight = useGetOfflineBannerHeight(isOffline);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      isScrollingDown.current = Boolean(scrollTop > lastScrollTop.current);
      lastScrollTop.current = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop.current]);

  const handleScrollEvent = () => {
    if (!orderGuideHeaderRef) return;

    if (orderGuideHeaderRef.current) {
      const siteHeader = document.getElementById('header-site');
      const siteHeaderHeight = siteHeader ? siteHeader.getBoundingClientRect().height : 0;
      const elementTop = orderGuideHeaderRef.current.getBoundingClientRect().top;

      // set siteHeaderHeight to state
      setSiteHeaderHeight(siteHeaderHeight);

      setIsIntersectionPoint(elementTop <= siteHeaderHeight);
      setIsHideHeader(elementTop <= siteHeaderHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);

    // Initial check in case the element is already at the top
    handleScrollEvent();

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  const mdBreakpoint = useBreakpointValue({ base: 'base', md: 'md' });
  const desktopBreakpoint = useBreakpointValue({ base: 'base', xl: 'xl' });

  const calculateHeaderTopPosition = (hideHeader: boolean) => {
    if (desktopBreakpoint === 'xl') return;

    const HEADER_HEADER_OFFSET = mdBreakpoint === 'md' ? 0 : -2.5;
    const SITE_HEADER_HEIGHT_OFFSET = mdBreakpoint === 'md' ? 0 : 8;

    if (hideHeader) {
      if (offlineBannerHeight > 0) {
        return `${offlineBannerHeight}px`;
      }
      return HEADER_HEADER_OFFSET;
    }
    return isIntersectionPoint ? `${siteHeaderHeight - SITE_HEADER_HEIGHT_OFFSET}px` : 'auto';
  };

  const shiftHeader = isIntersectionPoint && desktopBreakpoint !== 'xl';

  return {
    isScrollingDown: isScrollingDown.current,
    isHideHeader,
    setIsHideHeader,
    handleScroll: handleScrollEvent,
    isIntersectionPoint,
    siteHeaderHeight,
    calculateHeaderTopPosition,
    shiftHeader,
  };
};
