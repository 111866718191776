import { SWRConfiguration } from 'swr';
import { OrderGuideFilteredTags } from '../utils/filterOrderGuideByTags';

export const COMMERCETOOLS_PRODUCT_QUERY_LIMIT = 400;
export const SHAMROCK_API_QUERY_LIMIT = 50;
export const AUTOCOMPLETE_MAXIMUM_RESULTS = 5;
export const AUTOCOMPLETE_MINIMUM_CHARACTERS = 3;

// please changes these values in backend ticket
export const ORDER_GUIDE_EXPORT = '/order-guide/export/';
export const ORDER_GUIDE_CONVERT_TO_ORDER_GUIDE = '/order-guide/conert_to_order_guide/';

export const SORTS_KEYS_OG = {
  default: 'default',
  asc: 'asc',
  desc: 'desc',
  priceAsc: 'priceAsc',
  priceDesc: 'priceDesc',
  recentlyPurchased: 'recentlyPurchased',
  itemsInCart: 'itemsInCart',
};

//Before change any value from here first read this documentation
//https://swr.vercel.app/docs/api
export const SWR_OPTIONS: SWRConfiguration = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// Order guide page, purchase history option
export const SHOP_PURCHASE_HISTORY = 'shopPurchaseHistory';
export const TIME_FRAME_RANGE = {
  past7days: { name: 'past7days', days: 7 },
  past30days: { name: 'past30days', days: 30 },
  past90days: { name: 'past90days', days: 90 },
  past180days: { name: 'past180days', days: 180 },
};
export const OGPH_DEFAULT_NUMBER_OF_WEEKS = 2;

export const OG_LOCAL_STORAGE_INITIAL_STATE = {
  scrollPosition: 0,
  virtualizedScrollPosition: 0,
  searchTerm: '',
  selectedOrderGuide: '',
  selectedSortOptionOrderGuide: {
    selectedSort: 'default',
    onClickedSort: '',
  },
  purchaseHistoryNumberOfWeeks: OGPH_DEFAULT_NUMBER_OF_WEEKS,
  isParEnabled: false,
  wentOffline: false,
};

export const PH_LOCAL_STORAGE_INITIAL_STATE = {
  ...OG_LOCAL_STORAGE_INITIAL_STATE,
  selectedOrderGuide: SHOP_PURCHASE_HISTORY,
  selectedSortOptionOrderGuide: {
    selectedSort: SORTS_KEYS_OG.recentlyPurchased,
    onClickedSort: SORTS_KEYS_OG.recentlyPurchased,
  },
};

export const TEN_KEY = '10Key';

export const VIRTUALIZED_LIST_CLASS = 'virtualized-list-og';

export const labels = {
  [OrderGuideFilteredTags.JIT]: 'order.guide.made.to.order.results.label',
  [OrderGuideFilteredTags.SUR]: 'order.guide.ship.to.order.results.label',
  [OrderGuideFilteredTags.XDK]: 'order.guide.ship.to.order.results.label',
  [OrderGuideFilteredTags.DRY]: 'order.guide.dry.results.label',
  [OrderGuideFilteredTags.FROZEN]: 'order.guide.frozen.results.label',
  [OrderGuideFilteredTags.REFRIGERATED]: 'order.guide.refrigerated.results.label',
};

export const QUERY_PROPERTY = 't';

export enum QueryOG {
  PURCHASE_HISTORY = 'ph',
  TEN_KEY = '10k',
  ORDER_GUIDE = 'og',
}

export const QUERY_MAP = {
  [QueryOG.PURCHASE_HISTORY]: SHOP_PURCHASE_HISTORY,
  [QueryOG.TEN_KEY]: TEN_KEY,
};

export const OG_QTY_PERMANENT_STORAGE = 'og-qty-permament-storage';

export const HEADER_HEIGHT = 320;
export const FOOTER_HEIGHT = 50;
