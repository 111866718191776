import { ExtraProductData } from '@Types/shamrockApi/Product';
import { addDays, isAfter } from 'date-fns';

export function calculateLatestDeliveryDate(extraProductDataList: ExtraProductData[]) {
  let latestDate: Date = null;
  let incrementalLog: any = {};

  (extraProductDataList ?? []).forEach((extraData) => {
    const cutoffs = extraData?.companyInfo?.data?.cutoffs;
    // Only calculate date for products with lead time
    if ((cutoffs?.daysOfWeek?.length ?? 0) > 0) {
      let nextAvailableDate: Date = null;
      // Check lead day for each individual day for the next 30 days (arbritary number to avoid infinite loop)
      const today = new Date();
      const utcToday = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0));
      for (let daysToAdd = 0; daysToAdd < 30; daysToAdd++) {
        let deliveryDate = addDays(utcToday, daysToAdd);
        const dailyCutoff = cutoffs.daysOfWeek.find((cutoff) => cutoff.dayOfWeek === deliveryDate.getDay());
        if (dailyCutoff) {
          let cutoffDate = addDays(deliveryDate, -Math.abs(dailyCutoff.leadDays));
          const [hour, minute, second] = (dailyCutoff?.cutoffTime ?? '00:00:00').split(':');
          cutoffDate.setUTCHours(parseInt(hour, 10));
          cutoffDate.setUTCMinutes(parseInt(minute, 10));
          cutoffDate.setUTCSeconds(parseInt(second, 10));
          cutoffDate.setUTCMilliseconds(0);
          // Check if past cutoff for that delivery date
          if (isAfter(cutoffDate, today)) {
            nextAvailableDate = deliveryDate;
            break;
          }
        }
      }
      incrementalLog[extraData.productNumber] = { cutoffs: cutoffs, nextAvailableDate };

      // Update the latest delivery date if it's later
      if (!latestDate || isAfter(nextAvailableDate, latestDate)) {
        latestDate = nextAvailableDate;
      }
    } else if (extraData?.productNumber) {
      incrementalLog[extraData.productNumber] = { cutoffs: null, nextAvailableDate: '-' };
    }
  });
  const now = new Date();
  const todayDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));
  latestDate = latestDate ?? todayDate;
  incrementalLog.latestDate = latestDate;
  // Logging calculation so we can debug issue in LR
  console.log('Delivery Date Calculation', incrementalLog);
  return latestDate;
}
