import { useMemo } from 'react';
import { useGlobal } from 'components/globalProvider';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { ACCESS_TOKEN } from 'composable/helpers/constants';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { refreshAccessToken } from 'hooks/global/use_privateUserGlobal/utils';
import useSWR from 'swr';
import { deleteLocalStorageValuesWithoutBu } from 'frontastic/actions/account';
import { UserGlobalStateActions } from './global/use_privateUserGlobal/types';
import { validateAccessToken } from './global/use_privateUserGlobal/utils';

const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 5; // 5 minutes

export const useRefreshToken = () => {
  const { useUserGlobal, isOffline } = useGlobal();
  const {
    state: { refreshToken },
    dispatch,
    logout,
  } = useUserGlobal;

  const isValidRefreshToken = useMemo(() => validateAccessToken(refreshToken), [refreshToken]);

  try {
    useSWR(
      isValidRefreshToken && !isOffline ? 'refreshToken' : null,
      () =>
        refreshAccessToken(refreshToken, (getTokensResponse) => {
          window.localStorage.setItem(ACCESS_TOKEN, getTokensResponse.access_token);
          dispatch({
            type: UserGlobalStateActions.SET_ACCESS_TOKEN,
            payload: {
              accessToken: getTokensResponse.access_token,
              refreshToken: getTokensResponse.refresh_token,
              expiresOn: getTokensResponse.expires_on,
            },
          });
        }),
      { ...SWR_OPTIONS, refreshInterval: REFRESH_TOKEN_INTERVAL, refreshWhenHidden: true, errorRetryCount: 2 },
    );
  } catch (e) {
    deleteLocalStorageValuesWithoutBu();
    logout(true, AuthErrorKeys.SessionExpired);
  }
};
