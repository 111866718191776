// Added a delay to accommodate the API call needed to update the cart quantity.

import { KEYBOARD_KEYS, SEARCH_LOCAL_INPUT_ID } from 'composable/components/pdp/constants';

// Without this delay, the element that needs to be focused may not be available in the DOM.
export const DELAY_INPUT_FOCUS = 500;
const MAX_ATTEMPTS = 3;

export type FocusQuantityElementArgs = {
  sectionId: string;
  index: number;
  delay?: number;
  shouldClick?: boolean;
  moveScreen?: boolean;
};

type FocusElementByIdArgs = {
  id: string;
  moveScreen?: boolean;
  shouldClick?: boolean;
};

export const focusElementById = ({ id, moveScreen = false, shouldClick = false }: FocusElementByIdArgs) => {
  const element = document.getElementById(id) as HTMLInputElement;
  if (element) {
    shouldClick &&
      element.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: KEYBOARD_KEYS.Q,
          keyCode: 81,
          code: 'KeyQ',
          which: 81,
          bubbles: true,
        }),
      );
    const scrollPos = { top: window.scrollY, left: window.scrollX };
    element.focus();
    element.select?.();

    //Work around to avoid scroll to top when focusing an element
    moveScreen
      ? element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      : window.scrollTo(scrollPos.left, scrollPos.top);
  }
};

export const focusAddToCartButtonElement = (sectionId: string, index: number, delay?: number) => {
  setTimeout(() => {
    const id = `add-to-cart-button_${sectionId}_${index}`;
    if (document.getElementById(id)) {
      focusElementById({ id });
    }
  }, delay || DELAY_INPUT_FOCUS);
};

export const activateInputAfterAddToCart = (sectionId: string, index: number, delay?: number) => {
  setTimeout(() => {
    const id = `number-input-field_${sectionId}_${index}`;
    const isButtonStillActive = document.activeElement?.id === `add-to-cart-button_${sectionId}_${index}`;
    !isButtonStillActive && focusElementById({ id, moveScreen: false });
  }, delay || DELAY_INPUT_FOCUS);
};

export const focusFirstQuantityElement = () => {
  const focusableElements = Array.from(
    document.querySelectorAll('input[id^="number-input-field_"], button[id^="add-to-cart-button_"]'),
  );

  if (!focusableElements.length) {
    return;
  }

  const firstElementId = focusableElements[0]?.id;

  if (firstElementId.includes('add-to-cart-button')) {
    focusElementById({ id: firstElementId, shouldClick: true });
    return;
  }

  if (firstElementId.includes('number-input-field')) {
    focusElementById({ id: firstElementId });
  }
};

const findNextFocusableElement = (direction: 'next' | 'previous', fallbackIndex: number = -1) => {
  const currentElement = document.activeElement;

  const focusList = currentElement.getAttribute('data-focus-list');
  const focusIndex = parseInt(currentElement.getAttribute('data-focus-index'));

  const currentIndex = isNaN(focusIndex) ? fallbackIndex : focusIndex;

  if (currentIndex === -1) return null;

  if (currentIndex === 0 && direction === 'previous') {
    // User is already on the first element and wants to go back
    return null;
  }

  const nextIndex = currentIndex + (direction === 'next' ? 1 : -1);

  return document.querySelector(`[data-focus-list="${focusList}"][data-focus-index="${nextIndex}"]`) as HTMLElement;
};

export const focusNextQuantityElement = ({
  shouldClick = false,
  moveScreen = false,
  index,
}: Partial<FocusQuantityElementArgs>) => {
  const nextElement = findNextFocusableElement('next', index);
  if (nextElement) {
    focusElementById({ id: nextElement.id, shouldClick, moveScreen });
  }
};

export const focusPreviousQuantityElement = ({
  shouldClick = false,
  moveScreen = false,
}: Partial<FocusQuantityElementArgs>) => {
  const prevElement = findNextFocusableElement('previous');
  if (prevElement) {
    focusElementById({ id: prevElement.id, shouldClick, moveScreen });
  } else {
    const searchField = document.getElementById(`${SEARCH_LOCAL_INPUT_ID}-v2`);
    searchField?.focus();
  }
};

export const focusCurrentQuantityElement = ({
  sectionId,
  index,
  shouldClick = false,
  moveScreen = false,
}: FocusQuantityElementArgs) => {
  const id = `number-input-field_${sectionId}_${index}`;
  focusElementById({ id, shouldClick, moveScreen });
};
