import { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Box, Button, Flex, StackDivider, Text, VStack, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { OrderDetail, OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { getEstimatedDeliveryDate } from 'composable/components/order-details/components/parts/helpers/get-estimated-delivery-date';
import { formatDateWTimeZoneWithoutDay, getTimeZone } from 'composable/components/plp/helpers';
import { getDateWithCutoff } from 'composable/helpers/hooks/useGetDeliveryDates';
import {
  isCancelled,
  isDeliveredComplete,
  isOutForDelivery,
  isProcessing,
  isReadyForPickup,
  isSubmitted,
} from 'composable/helpers/utils/order-status';
import {
  isALaCartDeliveryType,
  isPickupDeliveryType,
  getDeliveryTypeCopyKey,
  isInvoiceReissueDeliveryType,
  isCustomerDirectDeliveryType,
} from 'composable/helpers/utils/order-utils';
import { getDeliveryInfo } from 'composable/helpers/utils/user-utils';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { permission } from 'helpers/constants/permissions';
import { useFormat } from 'helpers/hooks/useFormat';
import { Storefront, Truck } from 'phosphor-react';
import { CancelOrderModal, CANCEL_ORDER_STATUS } from './cancel-order-modal';
import { ViewPrintBackButton } from './view-printback-button';
import { CancelOrderStatus } from '../../order-details-page';
import { CreditRequestProvider } from '../credit-request-modal/context/credit-request-context';
import { OrderStatusMessage } from './order-status-message';

const CreditRequestModal = dynamic(() => import('../credit-request-modal/index'), { ssr: false });

type OrderDetailsCardProps = {
  isEditOrder: boolean;
  handleEditOrder(): void;
  orderDetail: OrderDetail;
  isLoadingOrders: boolean;
  status: string;
  cancelOrderStatus: CancelOrderStatus;
  setCancelOrderStatus: Dispatch<SetStateAction<CancelOrderStatus>>;
  toggleRefetchOrder: () => void;
  lineItems: OrderDetailLineItem[];
};

const mountEstimatedDeliveryTimeSlotUTC = (
  estimatedDeliveryDate: string | null,
  estimatedDeliveryTimeSlot: OrderDetail['estimatedDeliveryTimeSlot'] | null,
) => {
  if (!estimatedDeliveryDate || !estimatedDeliveryTimeSlot) {
    return null;
  }

  const startTimeUTC = new Date(`${estimatedDeliveryDate}T${estimatedDeliveryTimeSlot.deliveryWindowStartTime}Z`);
  const endTimeUTC = new Date(`${estimatedDeliveryDate}T${estimatedDeliveryTimeSlot.deliveryWindowEndTime}Z`);

  return {
    startTimeUTC,
    endTimeUTC,
  };
};

export const OrderDetailsCard: FunctionComponent<OrderDetailsCardProps> = ({
  orderDetail,
  handleEditOrder,
  isEditOrder,
  isLoadingOrders,
  status,
  cancelOrderStatus,
  setCancelOrderStatus,
  toggleRefetchOrder,
  lineItems,
}) => {
  const { activeAccount, shamrockUser, accountList } = useGlobal().useUserGlobal.state;
  const { softCutoffs } = useGlobal().useCutoffsGlobal;
  const customerPermissionsFromShamrock = shamrockUser?.user?.permissions?.data ?? [];

  const userTimezone = getTimeZone();

  const deliveryDate = orderDetail.estimatedDeliveryDate ? parseISO(orderDetail.estimatedDeliveryDate) : null;

  const deliveryInfo = getDeliveryInfo(activeAccount);
  const dateWithCutoff = getDateWithCutoff(
    deliveryDate,
    deliveryInfo.LeadDays,
    deliveryInfo.CutoffTime,
    softCutoffs,
  ) as Date;

  // Credit Request Modal
  const { isOpen: isOpenCredit, onOpen: onOpenCredit, onClose: onCloseCredit } = useDisclosure();

  const handleOpenCreditRequestModal = () => {
    onOpenCredit();
  };

  // Cancel Order Modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenCancelOrderModal = () => {
    onOpen();
    if (cancelOrderStatus !== null) {
      setCancelOrderStatus(null);
    }
  };

  const estimatedDeliveryDate = getEstimatedDeliveryDate({
    estimatedDeliveryDate: orderDetail.estimatedDeliveryDate,
    estimatedDeliveryTimeSlot: orderDetail.estimatedDeliveryTimeSlot,
  });

  const deliveredDate = orderDetail?.deliveredDateTime
    ? format(parseISO(orderDetail.deliveredDateTime), 'MM/dd/yyyy')
    : null;

  const deliveredTime = orderDetail?.deliveredDateTime
    ? `${format(new Date(orderDetail.deliveredDateTime), 'hh:mm a')} ${userTimezone}`
    : null;

  const estimatedDeliveryTimeSlot = orderDetail
    ? mountEstimatedDeliveryTimeSlotUTC(orderDetail.estimatedDeliveryDate, orderDetail.estimatedDeliveryTimeSlot)
    : null;

  const deliveryWindowStartTime =
    orderDetail && estimatedDeliveryTimeSlot?.startTimeUTC
      ? format(estimatedDeliveryTimeSlot.startTimeUTC, 'hh:mm a')
      : null;

  const deliveryWindowEndTime =
    orderDetail && estimatedDeliveryTimeSlot?.endTimeUTC
      ? format(estimatedDeliveryTimeSlot.endTimeUTC, 'hh:mm a')
      : null;

  const { formatMessage } = useFormat({ name: 'common' });

  // Get account name
  const buKey = orderDetail.customerNumber;
  const accountName = accountList.find((bu) => bu.key === buKey)?.name;

  const accountNameRow = orderDetail.customerNumber
    ? [formatMessage({ id: 'orderDetails.accountName' }), accountName]
    : null;

  const poNumberRow = [
    formatMessage({ id: 'orderDetails.poNumber' }),
    orderDetail.purchaseOrderNumber ? orderDetail.purchaseOrderNumber.replace('PO_', '').replace('PO', '') : '-',
  ];
  const invoiceOrderRow = orderDetail?.invoiceNumber
    ? [formatMessage({ id: 'orderDetails.invoiceOrder' }), orderDetail?.invoiceNumber]
    : null;

  const typeRow = [
    formatMessage({ id: 'orderDetails.type' }),
    formatMessage({ id: getDeliveryTypeCopyKey(orderDetail.orderType) }),
  ];

  const submittedRow = orderDetail.submittedDateTime
    ? [formatMessage({ id: 'orderDetails.submitted' }), format(new Date(orderDetail.submittedDateTime), 'MM/dd/yyyy')]
    : null;

  const lasModifiedRow = orderDetail.lastModifiedDateTime
    ? [
        formatMessage({ id: 'orderDetails.lastModified' }),
        format(new Date(orderDetail.lastModifiedDateTime), 'MM/dd/yyyy'),
      ]
    : null;

  const estimatedDeliveryRow = useMemo(() => {
    if (!estimatedDeliveryDate) {
      return null;
    }

    let value = estimatedDeliveryDate;

    if (deliveryWindowStartTime && deliveryWindowEndTime) {
      value += `,\n${deliveryWindowStartTime} - ${deliveryWindowEndTime} ${userTimezone}`;
    }

    return [formatMessage({ id: 'orderDetails.estimatedDelivery' }), value];
  }, [deliveryWindowEndTime, deliveryWindowStartTime, estimatedDeliveryDate, formatMessage, userTimezone]);

  const deliveryRow = estimatedDeliveryDate
    ? [formatMessage({ id: 'orderDetails.delivery' }), estimatedDeliveryDate]
    : null;

  const deliveredRow = [
    formatMessage({ id: 'orderDetails.delivered' }),
    deliveredDate ? `${deliveredDate},\n${deliveredTime}` : '-',
  ];

  const pickUpRow = deliveredDate ? [formatMessage({ id: 'orderDetails.pickUp' }), `03/30/2023`] : null;

  const specialHandlingInstructionsRow = [
    formatMessage({ id: 'orderDetails.specialHandlingInstructions' }),
    orderDetail.handlingInstructions.trim() || '-',
  ];

  const mountDetailsRows = () => {
    const rows = [accountNameRow, poNumberRow];

    if (isSubmitted(status)) {
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(lasModifiedRow);
      rows.push(estimatedDeliveryRow);
      rows.push(specialHandlingInstructionsRow);
    } else if (isProcessing(status)) {
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(lasModifiedRow);
      rows.push(estimatedDeliveryRow);
      rows.push(specialHandlingInstructionsRow);
    } else if (isOutForDelivery(status)) {
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(lasModifiedRow);
      rows.push(deliveryRow);
      rows.push(specialHandlingInstructionsRow);
    } else if (isReadyForPickup(status)) {
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(lasModifiedRow);
      rows.push(pickUpRow);
      rows.push(specialHandlingInstructionsRow);
    } else if (isDeliveredComplete(status)) {
      rows.push(invoiceOrderRow);
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(deliveredRow);
    } else if (isCustomerDirectDeliveryType(status)) {
      rows.push(invoiceOrderRow);
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(deliveredRow);
      rows.push(specialHandlingInstructionsRow);
    } else if (isInvoiceReissueDeliveryType(status)) {
      rows.push(invoiceOrderRow);
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(deliveredRow);
    } else {
      // canceld or empty status will be treated as cancelled
      rows.push(typeRow);
      rows.push(submittedRow);
      rows.push(lasModifiedRow);
    }

    return rows;
  };

  const detailsRows = mountDetailsRows();

  const statusMessageSeparator = useBreakpointValue({ base: ' ', '2xl': '\n' });

  const getStatusMessage = () => {
    const noInformation = !dateWithCutoff && !deliveryWindowStartTime && !deliveryWindowEndTime;
    const isOrderCancelled = isCancelled(status);
    if (noInformation || isOrderCancelled) {
      return;
    }

    if (isSubmitted(status) && dateWithCutoff) {
      return {
        icon: Truck,
        message: formatMessage({
          id: 'orderDetails.cutoffTimeMessage',
          values: {
            separator: statusMessageSeparator,
            day: format(dateWithCutoff, 'eeee'),
            time: `${formatDateWTimeZoneWithoutDay(dateWithCutoff)}`,
          },
        }),
      };
    }

    const shouldDisplayWindowInformation = deliveryWindowStartTime && deliveryWindowEndTime;
    const outForDelivery = isOutForDelivery(status);
    const readyForPickup = isReadyForPickup(status);

    if (estimatedDeliveryDate && !shouldDisplayWindowInformation) {
      return {
        icon: outForDelivery ? Truck : Storefront,
        message: formatMessage({
          id: 'orderDetails.estimatedDeliveryDate',
          values: {
            separator: statusMessageSeparator,
            date: estimatedDeliveryDate,
          },
        }),
      };
    }

    if (outForDelivery) {
      return {
        icon: Truck,
        message: formatMessage({
          id: 'orderDetails.estimatedDeliveryMessage',
          values: {
            separator: statusMessageSeparator,
            start: `${deliveryWindowStartTime}`,
            end: `${deliveryWindowEndTime} ${userTimezone}`,
          },
        }),
      };
    }

    if (readyForPickup) {
      return {
        icon: Storefront,
        message: formatMessage({
          id: 'orderDetails.availableForPickUpMessage',
          values: {
            separator: statusMessageSeparator,
            start: `${deliveryWindowStartTime ?? ''}`,
            end: deliveryWindowEndTime ? `${deliveryWindowEndTime} ${userTimezone}` : '',
          },
        }),
      };
    }
  };

  const statusMessage = getStatusMessage();

  const isOrderALaCarteOrPickupType =
    isALaCartDeliveryType(orderDetail?.orderType) || isPickupDeliveryType(orderDetail?.orderType);

  const canCancelOrder = customerPermissionsFromShamrock.includes(permission.CanCancelOrders);

  const isDisabled =
    isEditOrder ||
    !orderDetail.isEditable ||
    cancelOrderStatus === CANCEL_ORDER_STATUS.TIMEOUT ||
    (isOrderALaCarteOrPickupType && !isSubmitted(status));

  return (
    <Box background="white" borderRadius="lg" color="neutral.900" overflow="hidden" width="full">
      <Box bg="neutral.50" p={4}>
        <Text color="neutral.900" lineHeight="120%" fontSize="lg" fontWeight="bold">
          {formatMessage({ id: 'orderDetails.orderDetails' })}
        </Text>
      </Box>
      <VStack align="unset" divider={<StackDivider borderColor="neutral.100" />} py={{ base: 6, lg: 8 }} spacing={6}>
        <VStack align="unset" divider={<StackDivider borderColor="neutral.100" />} px={{ base: 6, lg: 8 }} spacing={1}>
          {!isLoadingOrders &&
            detailsRows.map((row) => {
              if (!row) {
                return null;
              }

              const asCol = [accountNameRow?.[0], specialHandlingInstructionsRow?.[0]].includes(row[0]);

              return (
                <Box
                  display="flex"
                  flexDirection={asCol ? 'column' : 'row'}
                  justifyContent={asCol ? 'unset' : 'space-between'}
                  key={row[0]}
                >
                  <Text
                    color="neutral.600"
                    lineHeight="150%"
                    fontSize="sm"
                    fontWeight="bold"
                    textAlign="left"
                    whiteSpace="pre-line"
                  >
                    {row[0]}
                  </Text>
                  <Text
                    color="neutral.600"
                    lineHeight="150%"
                    fontSize="sm"
                    textAlign={asCol ? 'left' : 'right'}
                    whiteSpace="pre-line"
                  >
                    {row[1]}
                  </Text>
                </Box>
              );
            })}
        </VStack>
        {!isInvoiceReissueDeliveryType(orderDetail.orderType) &&
          !isCustomerDirectDeliveryType(orderDetail.orderType) && (
            <OrderStatusMessage statusMessage={statusMessage} status={status} />
          )}

        {/* View Printback Button */}
        {(isOutForDelivery(status) || isReadyForPickup(status)) && <ViewPrintBackButton />}

        {isSubmitted(status) && (
          <VStack align="unset" px={{ base: 6, lg: 8 }} spacing={4}>
            <Button onClick={handleEditOrder} isDisabled={isDisabled} size="ds-lg" variant="ds-filled">
              {formatMessage({ id: 'orderDetails.editOrder' })}
            </Button>
            {canCancelOrder && (
              <>
                <Button
                  bgColor="#fff"
                  size="ds-lg"
                  variant="ds-ghost-on-light"
                  isDisabled={isDisabled}
                  onClick={handleOpenCancelOrderModal}
                >
                  {formatMessage({ id: 'orderDetails.cancelOrder' })}
                </Button>
              </>
            )}
          </VStack>
        )}

        {/* View Printback Button  */}
        {isSubmitted(status) && (
          <Flex flexDir="column">
            <ViewPrintBackButton />
          </Flex>
        )}

        {isDeliveredComplete(status) && (
          <Flex flexDir="column" gap={3}>
            {!isCustomerDirectDeliveryType(orderDetail.orderType) &&
              (isDeliveredComplete(status) || isInvoiceReissueDeliveryType(status)) && (
                <Button
                  size="ds-md"
                  mx="8"
                  bgColor="#fff"
                  variant="ds-ghost-on-light"
                  isDisabled={isEditOrder}
                  onClick={handleOpenCreditRequestModal}
                >
                  {formatMessage({ id: 'creditReturn.button' })}
                </Button>
              )}
            {/* View Printback Button */}
            <ViewPrintBackButton />
          </Flex>
        )}
      </VStack>

      {/* Cancel Order Modal */}
      <CancelOrderModal
        isOpen={isOpen}
        onClose={onClose}
        setCancelOrderStatus={setCancelOrderStatus}
        order={orderDetail}
        toggleRefetchOrder={toggleRefetchOrder}
      />

      {/* Credit Request Modal */}
      <CreditRequestProvider customerNumber={orderDetail?.customerNumber} orderNumber={orderDetail?.orderNumber}>
        <CreditRequestModal
          businessSegmentName={orderDetail?.businessSegmentName}
          customerNumber={orderDetail?.customerNumber}
          isOpen={isOpenCredit}
          onClose={onCloseCredit}
          invoice={orderDetail?.invoiceNumber}
          lineItems={lineItems}
        />
      </CreditRequestProvider>
    </Box>
  );
};
