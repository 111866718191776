export const SESSION_PERSISTENCE = 1000 * 60 * 60 * 24 * 30 * 3; //3 months

export enum AuthErrorKeys {
  ShamrockUserNotFound = 'shamrock_user_not_found',
  UserNotFound = 'user_not_found',
  NoAccountFound = 'no_account_found',
  NoWarehouseFound = 'no_warehouse_found',
  Unauthorized = 'unauthorized',
  SessionExpired = 'session_expired',
  Network = 'network_error',
}

export const ERROR_DICTIONARY = {
  [AuthErrorKeys.ShamrockUserNotFound]: 'login.error.message.shamrock.user.not.found',
  [AuthErrorKeys.UserNotFound]: 'login.error.message.user.not.found',
  [AuthErrorKeys.NoAccountFound]: 'login.error.message.account.not.found',
  [AuthErrorKeys.NoWarehouseFound]: 'login.error.message.wharehouse.not.found',
  [AuthErrorKeys.Unauthorized]: 'login.error.message.unauthorized',
  [AuthErrorKeys.SessionExpired]: 'login.error.message.session_expired',
  [AuthErrorKeys.Network]: 'login.error.message.network',
};
