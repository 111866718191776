import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Product } from '@Types/product/Product';
import { useFormat } from 'helpers/hooks';
import { query } from 'frontastic/actions/product';

const REQUEST_MADE_SHARED = {
  skus: [] as string[],
  products: [] as Product[],
  inProgress: false,
};

let apiResponseTime: number | null = null;

type UseGetCtProductsOptions = {
  useIndividualRequest?: boolean;
  conditionalCall?: boolean;
};

/**
 * Get CT products information by SKU
 * @param skus Array of skus
 * @param options object with options
 * @returns products and isLoading
 */
export const useGetCtProducts = (
  skus: string[],
  options: UseGetCtProductsOptions = { useIndividualRequest: false, conditionalCall: false },
) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const uniqueSkus = [...new Set(skus)];

  const REQUEST_MADE = options.useIndividualRequest ? { ...REQUEST_MADE_SHARED } : REQUEST_MADE_SHARED;

  useEffect(() => {
    if (!uniqueSkus.length || options.conditionalCall) {
      setProducts([]);
      setIsLoading(false);
      return;
    }

    const currentSkusKey = [...new Set(skus)].sort().join(',');
    const cachedSkusKey = REQUEST_MADE.skus.sort().join(',');

    const fetchProducts = async () => {
      if (currentSkusKey === cachedSkusKey && REQUEST_MADE.products.length > 0) {
        setProducts(REQUEST_MADE.products);
        if (isLoading) {
          setIsLoading(false);
        }
        return;
      }

      if (REQUEST_MADE.inProgress) {
        return;
      }

      try {
        REQUEST_MADE.inProgress = true;
        if (!isLoading) {
          setIsLoading(true);
        }

        const startTime = performance.now();
        const response = await query(
          {
            skus: uniqueSkus,
            limit: uniqueSkus.length,
          },
          toast,
          formatMessage({ id: 'app.generic.error' }),
        );
        const endTime = performance.now();
        apiResponseTime = endTime - startTime;

        REQUEST_MADE.skus = uniqueSkus;
        REQUEST_MADE.products = response;
        setProducts(response);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        if (isLoading) {
          setIsLoading(false);
        }
        REQUEST_MADE.inProgress = false;
      }
    };

    fetchProducts();
  }, [uniqueSkus.join(), REQUEST_MADE.inProgress]);

  return { products, isLoading };
};

export const getCtProductsResponseTime = (): number | null => {
  return apiResponseTime;
};
