import { AsAssociateBusinessUnitResult, DeliverySchedule } from "@Types/business-unit/BusinessUnit";
import { NextDeliveryDate } from "composable/components/next-delivery-date";
import { DeliveryDatesProps, getPlanByStoreKey } from "composable/helpers/hooks";
import { addDays, addHours, addMinutes, differenceInDays, differenceInHours, differenceInMinutes, isBefore } from "date-fns";

export type ListAccountNextDeliveriesParams = {
  account: AsAssociateBusinessUnitResult;
  startDate: Date;
  maxDeliveries?: number;
  maxDaysAfterFirstDelivery?: number;
};

export type DeliveryDateWithCutoff = {
  deliveryDate: Date;
  cutoff: Date;
};

export function parseDeliveryDateWithCutoffToDeliveryDatesProps(list: DeliveryDateWithCutoff[]): DeliveryDatesProps {
  const orderByDate = list?.[0]?.cutoff;
  let days = null;
  let hours = null;
  let minutes = null;
  const now = new Date();
  if (orderByDate && isBefore(now, orderByDate)) {
    let helperDate = new Date(orderByDate);
    days = differenceInDays(helperDate, now);
    helperDate = addDays(helperDate, -days);

    hours = differenceInHours(helperDate, now);
    helperDate = addHours(helperDate, -hours);

    minutes = differenceInMinutes(helperDate, now);
  }
  return {
    dates: list.map((item) => item.deliveryDate),
    orderBy: {
      orderByDate,
      days,
      hours,
      minutes,
    },
  };
}

export function listAccountNextDeliveries({
  account,
  startDate,
  maxDeliveries,
  maxDaysAfterFirstDelivery = 7,
}: ListAccountNextDeliveriesParams): DeliveryDateWithCutoff[] | null {
  if (account == null || startDate == null) {
    return null;
  }
  if (maxDeliveries === 0) {
    return [];
  }
  let deliveryDates: DeliveryDateWithCutoff[] = [];
  let nextDelivery = getNextAccountDelivery(account, startDate);
  let maxDeliveryDay: Date = null;
  const now = new Date();
  // Maxing at 7 deliveries to avoid infinite loop
  for (let i = 0; i < 7; i++) {
    // If couldn't calculate the next delivery, return
    if (nextDelivery == null) {
      break;
    }
    // Only work with this delivery if cutoff is not past
    // if (nextDelivery.cutoff > now) {

    // Only add delivery if not past the maxDaysAfterFirstDelivery
    if (maxDeliveryDay == null || nextDelivery.deliveryDate < maxDeliveryDay) {
      deliveryDates.push(nextDelivery);
    }

    // If we reached maxDeliveries, return
    if (deliveryDates.length >= maxDeliveries) {
      break;
    }

    // If maxDaysAfterfirstDelivery was provided set the maxDeliveryDay
    if (maxDaysAfterFirstDelivery != null && deliveryDates.length === 1) {
      maxDeliveryDay = addDays(nextDelivery.deliveryDate, maxDaysAfterFirstDelivery);
      maxDeliveryDay.setUTCHours(23, 59, 59, 999);
    }
    // }
    nextDelivery = getNextAccountDelivery(account, addDays(nextDelivery.deliveryDate, 1));
  }
  return deliveryDates;
}

/**
 * Retrieves the next available delivery date and its cutoff date for a given account starting from a specified date.
 *
 * @param {AsAssociateBusinessUnitResult} account - The active account containing delivery schedules.
 * @param {Date} startDate - The date from which to start searching for the next delivery.
 * @returns {{ deliveryDate: Date; cutoff: Date } | null} An object containing the next delivery date and its cutoff date, or null if no delivery is found.
 *
 * @throws {Error} If no plan is found for the account/warehouse combination.
 * @throws {Warning} If more than one plan is found for the same warehouse.
 */
export function getNextAccountDelivery(
  account: AsAssociateBusinessUnitResult,
  startDate: Date,
): DeliveryDateWithCutoff {
  let nextDelivery = null;
  // Extract and sort list of schedules
  const plans = getPlanByStoreKey(account);
  if (plans == null || plans?.length === 0) {
    console.error('No plan for the account/warehouse combination');
    return null;
  }
  if (plans?.length > 1) {
    console.warn('More than a single plan for the same warehouse');
  }
  const sortedSchedules = plans[0].DeliverySchedules.sort((scheduleA, scheduleB) => {
    return new Date(scheduleA.StartDateTime).getTime() - new Date(scheduleB.StartDateTime).getTime();
  });

  const now = new Date();
  let possibleDelivery = new Date(startDate);
  sortedSchedules.forEach((schedule) => {
    // Check if possibleDelivery is within the schedule range and there's delivery days
    if (new Date(schedule.StartDateTime) > possibleDelivery && schedule?.DeliveryDays?.length > 0) {
      return;
    }
    const endDate = schedule.EndDateTime != null ? new Date(schedule.EndDateTime) : null;
    // avoid while(true)
    for (let i = 0; i < 7; i++) {
      // Check if the possibleDelivery is within the schedule range
      if (endDate != null && possibleDelivery > endDate) {
        break;
      }

      const weekDay = possibleDelivery.getDay();
      // Confirm there's a delivery on that day
      if (schedule.DeliveryDays.includes(weekDay)) {
        nextDelivery = {
          deliveryDate: possibleDelivery,
          cutoff: getDeliveryCutoff(possibleDelivery, schedule, account.lastModifiedAt),
        };
        return;
      }
      possibleDelivery = addDays(possibleDelivery, 1);
    }
  }); // sortedSchedules.forEach

  return nextDelivery;
}

/**
 * Calculates the delivery cutoff date and time based on the provided delivery date, schedule, and fallback anchor date.
 *
 * @param {Date} deliveryDate - The date of the delivery.
 * @param {DeliverySchedule} schedule - The delivery schedule containing lead days and cutoff time.
 * @param {string} anchorDateFallback - The fallback anchor date in case the schedule's anchor date is not provided.
 * @returns {Date | null} The calculated delivery cutoff date and time, or null if the delivery date or schedule is not provided.
 */
export function getDeliveryCutoff(deliveryDate: Date, schedule: DeliverySchedule, anchorDateFallback: string): Date {
  if (!deliveryDate || !schedule) {
    return null;
  }
  let cutoff = addDays(deliveryDate, -Math.abs(schedule.LeadDays));
  cutoff = new Date(`${cutoff.toISOString().split('T')[0]}T${schedule.CutoffTime}Z`);
  // if cutoff time is midnight we need to reduce one lead day
  if (schedule.CutoffTime === '00:00:00') {
    cutoff = addDays(cutoff, 1);
  }

  // DST
  const anchorDate = schedule.AnchorDateTime != null ? new Date(schedule.AnchorDateTime) : new Date(anchorDateFallback);
  cutoff = addMinutes(cutoff, new Date().getTimezoneOffset() - anchorDate.getTimezoneOffset());
  return cutoff;
}
