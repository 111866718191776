import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { useGlobal } from 'components/globalProvider';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { SHAMROCK_API_QUERY_LIMIT, SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { createProductExtraQueryParams } from 'helpers/utils/createExtraProductDataPayload';
import chunk from 'lodash/chunk';
import useSWR, { useSWRConfig } from 'swr';
import { getProductExtendedData } from 'frontastic/actions/shamrockApi';
import { useFormat } from './useFormat';
import { useShouldExecuteApi } from './useHandleCancelApiCall';

export const useProductExtraDataExecutable = (skus: string[], page?: string) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const { isPrintBack, useUserGlobal } = useGlobal();
  const { state: user, logout } = useUserGlobal;
  const { accessToken, activeWarehouse, activeAccount, loading: isLoadingUserData, accountList } = user;
  const { cache } = useSWRConfig();
  const listOfAccountNumbers = accountList?.map((account) => account.key) ?? [];
  // Generate query parameters for the product data request
  const queryParams = createProductExtraQueryParams(
    accessToken,
    activeAccount?.key,
    activeWarehouse,
    listOfAccountNumbers,
    page,
  );

  const handleToastError = useCallback(() => {
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: formatMessage({ id: 'app.generic.error' }),
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
  }, [toast, formatMessage]);

  const fetcher = async () => {
    const chunks = chunk(skus, SHAMROCK_API_QUERY_LIMIT);

    const productExtraDataPromises = chunks.map(async (chunk) => {
      const payload = {
        ...queryParams,
        productNumbers: chunk,
      };

      try {
        const productExtraData = await getProductExtendedData(payload, cache);
        return productExtraData?.products ?? [];
      } catch (e) {
        console.log('Error fething extra data:', e);
        handleToastError();
        return [];
      }
    });

    const shamrockResponse = await Promise.all(productExtraDataPromises);
    return [].concat(...shamrockResponse);
  };

  const { data, isLoading, isValidating } = useSWR(
    () =>
      accessToken && skus.length > 0 && !isPrintBack
        ? [JSON.stringify(skus), activeAccount?.key, activeWarehouse, listOfAccountNumbers, page]
        : null,
    fetcher,
    {
      ...SWR_OPTIONS,
      errorRetryCount: 2,
    },
  );

  return {
    extraProductData: (data || []) as ExtraProductData[],
    isLoadingShamrockData: isLoading || isLoadingUserData || isValidating,
    isValidatingShamrockData: isValidating,
  };
};

//eslint-disable-next-line
export const useProductExtraData = useShouldExecuteApi(useProductExtraDataExecutable, {
  extraProductData: [],
  isLoadingShamrockData: false,
  isValidatingShamrockData: false,
});
