import { useRouter } from 'next/router';
import { CURRENT_OG, OG_LOCAL_STORAGE, PH_LOCAL_STORAGE } from 'composable/helpers/constants/localStorageKeys';
import { useLocalStorage } from 'composable/helpers/hooks/useLocalStorage';
import {
  OG_LOCAL_STORAGE_INITIAL_STATE,
  PH_LOCAL_STORAGE_INITIAL_STATE,
  SHOP_PURCHASE_HISTORY,
  TEN_KEY,
} from './constants';
import { OrderGuideLocalStorageValues } from './types';
import { useIsBrowser } from 'composable/helpers/hooks';

// TODO: remove /uat with merge is done
const ALLOWED_ROUTES_TO_SAVE_SEARCH_TERMS = ['/order-guide', '/order-guide/management'];

export const useLocalStorageOrderGuide = () => {
  const isBrowser = useIsBrowser();
  const { asPath } = useRouter();
  const [ogLocalStorageValues, setOGLocalStorageValues] = useLocalStorage<OrderGuideLocalStorageValues>(
    OG_LOCAL_STORAGE,
    OG_LOCAL_STORAGE_INITIAL_STATE,
  );
  const [phLocalStorageValues, setPHLocalStorageValues] = useLocalStorage<OrderGuideLocalStorageValues>(
    PH_LOCAL_STORAGE,
    PH_LOCAL_STORAGE_INITIAL_STATE,
  );

  const currentOG = isBrowser ? localStorage.getItem(CURRENT_OG) : '';
  const isPh = currentOG === SHOP_PURCHASE_HISTORY;

  const setValue = (key: keyof OrderGuideLocalStorageValues, value: any) => {
    if (ALLOWED_ROUTES_TO_SAVE_SEARCH_TERMS.find((route) => asPath.includes(route))) {
      if (key === 'selectedOrderGuide' && [TEN_KEY].includes(value)) {
        return;
      }

      const setFunction = isPh ? setPHLocalStorageValues : setOGLocalStorageValues;

      const valueFromLocalStorage = JSON.parse(
        window.localStorage.getItem(isPh ? PH_LOCAL_STORAGE : OG_LOCAL_STORAGE) as string,
      ) as OrderGuideLocalStorageValues;

      setFunction({
        ...(valueFromLocalStorage ? valueFromLocalStorage : OG_LOCAL_STORAGE_INITIAL_STATE),
        [key]: value,
      });
    }
  };

  const setAllValues = (values: OrderGuideLocalStorageValues) => {
    values.selectedOrderGuide === SHOP_PURCHASE_HISTORY
      ? setPHLocalStorageValues({ ...values })
      : setOGLocalStorageValues({ ...values });
  };

  return {
    ogLocalStorageValues: isPh ? phLocalStorageValues : ogLocalStorageValues,
    setValue,
    setAllValues,
  };
};
