import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Cart } from '@Types/cart/Cart';
import { CART_HAS_BEEN_SUBMITTED_ID, TOAST_ICON } from 'composable/components/general';
import { DraftCartValues } from 'composable/components/mini-cart/helpers';
import { SELECTED_BUSINESS_UNIT_KEY, IS_SPLIT_ORDER } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { hasCreditOnHoldInfo } from 'composable/helpers/utils/user-utils';
import { isSuperUserPage as isSuperUserPageFn } from 'helpers/slugHelpers';
import { getCartById } from 'frontastic/actions/cart';
import { useFormat } from './useFormat';

export const ORDERED_STATUS = 'Ordered';

export const useSubmittedCartHandler = (
  cart: Cart | null,
  setDraftCart: () => void,
  fetchAllCarts: () => Promise<Cart[]>,
  user: Partial<AsAssociateBusinessUnitResult>,
) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const cartId = useRef<string | null>(cart?.cartId);
  const lineItems = cart?.lineItems;
  const [selectedBU] = useLocalStorage(SELECTED_BUSINESS_UNIT_KEY);
  const activeAccount = useRef(selectedBU);
  const { asPath } = useRouter();
  const isSuperUserPage = isSuperUserPageFn(asPath);
  const hasCreditOnHold = hasCreditOnHoldInfo(user);

  function fireToast(isCartDeleted: boolean = false) {
    const isSplitOrder = window.localStorage.getItem(IS_SPLIT_ORDER);
    if (!toast.isActive(CART_HAS_BEEN_SUBMITTED_ID) && !isSuperUserPage && !isSplitOrder && !hasCreditOnHold) {
      toast({
        duration: 5000,
        id: CART_HAS_BEEN_SUBMITTED_ID,
        status: 'warning',
        title: isCartDeleted
          ? formatMessage({ id: 'cart.hasBeenDeleted' })
          : formatMessage({ id: 'cart.hasBeenSubmitted' }),
        icon: TOAST_ICON.warning,
      });
    }
  }

  async function resetNotFoundCart(isCartDeleted: boolean = false) {
    fireToast(isCartDeleted);
    setDraftCart();
    await fetchAllCarts();
  }

  useEffect(() => {
    if (cart?.cartId && cart.cartId !== cartId.current) {
      cartId.current = cart.cartId;
    }
  }, [cart?.cartId]);

  useEffect(() => {
    if (selectedBU !== activeAccount.current) {
      activeAccount.current = selectedBU;
      return;
    }
    if (lineItems?.length > 0) {
      console.log('Empty cart', cartId.current);
    }

    if (cartId.current && cartId.current !== DraftCartValues.CART_ID) {
      getCartById(cartId.current).then((cart) => {
        if (!cart) {
          resetNotFoundCart(true);
        }
        if (cart?.status === ORDERED_STATUS) {
          resetNotFoundCart();
        }
      });
    }
  }, [asPath]);

  return { resetNotFoundCart };
};
