import { AxiosError } from 'axios';
import { IS_UNAUTHORIZED } from 'composable/helpers/constants';
import { AuthErrorKeys } from 'helpers/constants/auth';

export const handleAxiosError = async (
  error: AxiosError,
  logout: (hardLogout?: boolean, error_message?: string, isUnauthorized?: boolean) => Promise<void>,
) => {
  console.error('Error API call: handleAxiosError', error);
  if ([401, 403].includes(error?.response?.status)) {
    await logout(false, AuthErrorKeys.SessionExpired, true);
    localStorage.setItem(IS_UNAUTHORIZED, 'true');
  }
};
