import { AsAssociateBusinessUnitResult, DeliveryPlan, DeliverySchedule } from '@Types/business-unit/BusinessUnit';

/**
 * Filter the DeliverySchedules based on start and end datetimes
 * @param schedules DeliverySchedule[] from the CT BusinessUnit
 * @param datetime Date to check against the schedules
 * @returns DeliverySchedule for the given datetime
 */
export function filterDeliveryScheduleByDate(schedules: DeliverySchedule[], datetime: Date): DeliverySchedule {
  const schedule = schedules.find((schedule) => {
    // Ensure currentDate is not before the StartDateTime
    const startDateTime = new Date(schedule.StartDateTime);
    const endDateTime = schedule.EndDateTime ? new Date(schedule.EndDateTime) : null;
    if (datetime < startDateTime) {
      return false;
    }
    if (endDateTime != null && datetime > endDateTime) {
      return false;
    }

    return true;
  });

  // if we don't find a schedule, return the first one available avoiding to crash
  return schedule || schedules[0];
}

export function getDeliveryPlanForActiveWarehouse(businessUnit: AsAssociateBusinessUnitResult): DeliveryPlan {
  const deliveryPlans: DeliveryPlan[] = JSON.parse(businessUnit.custom.fields.sfc_business_unit_delivery_plans);
  return getDeliveryPlanForWarehouse(deliveryPlans, businessUnit.stores[0].key);
}

export function getDeliveryPlanForWarehouse(plans: DeliveryPlan[], warehouseKey: string): DeliveryPlan {
  return plans.find((plan) => {
    return plan.StoreKey.toLowerCase() === warehouseKey.toLowerCase();
  });
}
