import { ExtraProductData } from '@Types/shamrockApi/Product';

export enum InventoryStatus {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  OUT = 'OUT',
  JIT = 'JIT',
  JITMEAT = 'JITMEAT',
  JITFISH = 'JITFISH',
  SUR = 'SUR',
  UNAVAILABLE = 'UNAVAILABLE',
  INACTIVE = 'INACTIVE',
  PERMANENTOOS = 'PERMANENTOOS',
  OBSOLETE = 'OBSOLETE',
  NOT_FOUND = 'NOT_FOUND',
  NULL = 'NULL',
}

export enum PrincingInfo {
  NOT_FOUND = 'NotFound',
}

export type InventoryInfo = Pick<ExtraProductData, 'nextDeliveryDueDates'> & {
  status: InventoryStatus;
  availableQuantity?: number;
  nextDeliveryDueDate?: Date;
};

export const getInventoryStatusIcon = (status: InventoryStatus) => {
  const statusIconMap: { [key in InventoryStatus]: string } = {
    [InventoryStatus.HIGH]: 'green-filled.svg',
    [InventoryStatus.MEDIUM]: 'green-filled.svg',
    [InventoryStatus.LOW]: 'orange-half-filled.svg',
    [InventoryStatus.OBSOLETE]: 'gray-half-divided.svg',
    [InventoryStatus.UNAVAILABLE]: 'red-outlined.svg',
    [InventoryStatus.OUT]: 'red-outlined.svg',
    [InventoryStatus.JIT]: 'violet-filled.svg',
    [InventoryStatus.SUR]: 'violet-filled.svg',
    [InventoryStatus.JITMEAT]: 'violet-filled.svg',
    [InventoryStatus.JITFISH]: 'violet-filled.svg',
    [InventoryStatus.INACTIVE]: 'red-outlined.svg',
    [InventoryStatus.PERMANENTOOS]: 'red-outlined.svg',
    [InventoryStatus.NOT_FOUND]: 'red-outlined.svg',
    [InventoryStatus.NULL]: 'red-outlined.svg',
  };

  return statusIconMap[status] || null;
};

export const getInventoryInfo = (data: Partial<ExtraProductData>): InventoryInfo => {
  try {
    let status: InventoryStatus;
    const isShipUponReceipt = data?.companyInfo?.data?.isShipUponReceipt;

    if (!data?.inventoryInfo) {
      status = InventoryStatus.NULL;
    } else if (data?.inventoryInfo?.status === 'NotFound') {
      status = InventoryStatus.NOT_FOUND;
    } else {
      if (isShipUponReceipt) {
        status = InventoryStatus.SUR;
      } else {
        status = InventoryStatus[data?.inventoryInfo?.data?.availability];
      }
    }

    const inventoryInfo = {
      status,
      availableQuantity: data?.inventoryInfo?.data?.availableQuantity || 0,
      nextDeliveryDueDates: data?.nextDeliveryDueDates,
      nextDeliveryDueDate: data?.nextDeliveryDueDates?.data?.[0]?.dateTime
        ? new Date(data?.nextDeliveryDueDates?.data?.[0]?.dateTime)
        : undefined,
    };
    return inventoryInfo;
  } catch (error) {
    console.error('Failed to parse InventoryInfo values:', error);
    return {} as InventoryInfo;
  }
};
