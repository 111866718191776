import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Box, Flex, HStack, StackProps } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { PageVariant } from 'composable/components/types';
import {
  isCancelled,
  isDeliveredComplete,
  isOutForDelivery,
  isProcessing,
  isReadyForPickup,
  isSubmitted,
} from 'composable/helpers/utils/order-status';
import { isCustomerDirectDeliveryType } from 'composable/helpers/utils/order-utils';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import * as Parts from './parts';
import { Price } from '../price';
import { productIsActive, productIsSpecialOrder } from 'helpers/utils/productTypeHandler';
import { isProductOrderable as isProductOrderableFunction } from 'composable/helpers/utils/is-product-orderable';

type OrderSummaryVariantProps = {
  brand: string;
  name: string;
  id: string;
  packSize: string;
  currentPrice: number;
  discountedPrice: number;
  setSplitOrder?: Dispatch<SetStateAction<boolean>>;
  splitOrder?: boolean;
  priceClusterProps?: StackProps;
  extraProductData: ExtraProductData;
  nonRefundable?: boolean;
  perishable?: boolean;
  alertFlaggedForCasesOrdered?: boolean;
  dueDate?: Date;
  casesOrdered: number;
  allocated: number;
  invoiced?: number;
  received?: number;
  quantity?: number;
  cases?: number;
  hasCreditIssued?: boolean;
  orderStatus?: string;
  isEditCheckout?: boolean;
};

export const OrderSummaryVariant: FunctionComponent<OrderSummaryVariantProps> = ({
  brand,
  name,
  id,
  packSize,
  currentPrice,
  discountedPrice,
  priceClusterProps,
  nonRefundable,
  perishable,
  alertFlaggedForCasesOrdered,
  dueDate,
  casesOrdered,
  allocated,
  invoiced,
  received,
  hasCreditIssued,
  orderStatus,
  isEditCheckout,
  extraProductData,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });

  const isItemCancelled = orderStatus && isCancelled(orderStatus);

  const isProductActive = productIsActive(extraProductData);
  const isSpecialOrderProduct = productIsSpecialOrder(extraProductData);
  const isProductOrderable = isProductOrderableFunction(extraProductData?.status);
  const isProductAvailable = isProductActive && !isSpecialOrderProduct && isProductOrderable;

  return (
    <Parts.Card
      flexDirection="column"
      position="relative"
      background={isItemCancelled ? '#dddde1' : 'white'}
      __css={
        isItemCancelled && {
          '&::after': {
            position: 'absolute',
            content: '""',
            background: 'url("/images/vertical-stroke.svg") repeat',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.5,
          },
        }
      }
    >
      <Flex flexDirection={{ base: 'column', lg: 'row' }}>
        <Parts.Info breakpointTokenToDesktop="lg">
          <Parts.Brand breakpointTokenToDesktop="2xl">{brand}</Parts.Brand>
          <Parts.Name slug={id} clickable={isProductAvailable} breakpointTokenToDesktop="2xl">
            {name}
          </Parts.Name>
          <HStack alignItems="center" spacing={2}>
            <Parts.Id breakpointTokenToDesktop="2xl">{id}</Parts.Id>
            <Parts.PackSize breakpointTokenToDesktop="2xl">{packSize}</Parts.PackSize>
          </HStack>
          {nonRefundable && <Parts.RefundableItem breakpointTokenToDesktop="2xl" />}
          {perishable && <Parts.PerishableItem breakpointTokenToDesktop="2xl" />}
        </Parts.Info>
        <Parts.PriceCluster
          align="flex-start"
          breakpointTokenToDesktop="lg"
          w={{ base: 'unset', lg: '216px' }}
          {...priceClusterProps}
        >
          <Price
            priceProps={{
              fontWeight: 'bold',
              fontSize: { base: 'base', '2xl': 'lg' },
              lineHeight: { base: '19.2px', '2xl': '24px' },
              color: 'yellow.600',
            }}
            specialPriceProps={{
              fontWeight: 'normal',
              fontSize: { base: 'sm', '2xl': 'base' },
              textDecoration: 'line-through',
              lineHeight: { base: '16.8px', '2xl': '19.2px' },
              color: 'neutral.600',
              pt: { base: '2px', '2xl': '4px' },
            }}
            regularPrice={currentPrice ? CurrencyHelpers.formatForCurrency(currentPrice) : '$0.00'}
            specialPrice={discountedPrice ? CurrencyHelpers.formatForCurrency(discountedPrice) : '$0.00'}
            pageVariant={PageVariant.OrderDetails}
            orderStatus={orderStatus}
          />
          <Parts.Attributes
            breakpointTokenToDesktop="2xl"
            alertFlaggedForCasesOrdered={
              orderStatus &&
              !isCustomerDirectDeliveryType(orderStatus) &&
              !isDeliveredComplete(orderStatus) &&
              !isReadyForPickup(orderStatus) &&
              !isCancelled(orderStatus) &&
              alertFlaggedForCasesOrdered
            }
            dueDate={orderStatus && (isSubmitted(orderStatus) || isProcessing(orderStatus)) ? dueDate : undefined}
            casesOrdered={casesOrdered}
            allocated={
              orderStatus &&
              !isOutForDelivery(orderStatus) &&
              !isReadyForPickup(orderStatus) &&
              !isDeliveredComplete(orderStatus)
                ? allocated
                : null
            }
            invoiced={
              orderStatus && !isProcessing(orderStatus) && !isSubmitted(orderStatus) && !isCancelled(orderStatus)
                ? invoiced
                : null
            }
            received={orderStatus && isDeliveredComplete(orderStatus) ? received : null}
            hasCreditIssued={hasCreditIssued}
            quantity={null} // No status is displaying quantity
            cases={null} // No status is displaying cases
            isEditCheckout={isEditCheckout}
          />
        </Parts.PriceCluster>
      </Flex>
      {orderStatus && !isCancelled(orderStatus) && hasCreditIssued && (
        <Box as="article" bg="warning-light" overflow="clip" display="flex" flex={1} padding={{ base: '8px 16px' }}>
          <Parts.WarningMessage>
            {formatMessage({
              id: 'productCard.creditIssued',
            })}
          </Parts.WarningMessage>
        </Box>
      )}
    </Parts.Card>
  );
};
