import { FunctionComponent, PropsWithChildren } from 'react';
import { Stack, StackProps } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';

type PriceClusterProps = StackProps & {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
};

export const PriceCluster: FunctionComponent<PropsWithChildren<PriceClusterProps>> = ({
  breakpointTokenToDesktop = 'md',
  children,
  ...props
}) => {
  return (
    <Stack
      className="price-cluster"
      align="unset"
      borderColor="neutral.100"
      borderLeftWidth={{ base: 0, [breakpointTokenToDesktop]: 1 }}
      borderTopWidth={{ base: 1, [breakpointTokenToDesktop]: 0 }}
      direction="column"
      justify="space-between"
      py={{ base: 3, [breakpointTokenToDesktop]: 4 }}
      px={{ base: 3, [breakpointTokenToDesktop]: 4 }}
      spacing={{ base: 3, md: 4 }}
      {...props}
    >
      {children}
    </Stack>
  );
};
