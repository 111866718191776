import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Icon, ListItem, Link as ChakraLink, Box } from '@chakra-ui/react';
import { LeftSideMenuLabels, excludedShopCategoriesLabel } from 'composable/components/cms-components/left-side-menu';
import { useComposable } from 'composable/components/composable-provider';
import { SideMenuItemChild } from 'composable/types';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { CaretRight } from 'phosphor-react';
import { useOrderGuideChildItems } from './hooks/use-order-guide-child-items';
import { isOrderGuidePage } from './utils';

interface ChildrenMenuItemProps {
  item: SideMenuItemChild;
  onClick: () => void;
  isSelected: (path: string) => boolean;
  isMobileDrawer?: boolean;
}

export const ChildrenMenuItem: React.FC<ChildrenMenuItemProps> = ({
  item,
  isSelected,
  onClick,
  isMobileDrawer = false,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { ogChildItems, ogChildItemSelected } = useOrderGuideChildItems(item?.label);
  const { asPath: path, push } = useRouter();
  const isOrderGuideSelected = isOrderGuidePage(path);
  const { megaDrawer } = useComposable();

  const routePath = item.link.type === 'link' ? item.link.link : item.link.pageFolder?._url;
  // SHAM-2570
  const backgroundColor =
    item.label === LeftSideMenuLabels.ShopCategories && routePath.includes(routes.CATALOG)
      ? 'rgba(31, 31, 35, 0.50)'
      : (isSelected(item.link.link) && !excludedShopCategoriesLabel.includes(item.label as LeftSideMenuLabels)) ||
        (ogChildItemSelected && isOrderGuideSelected)
      ? 'neutral.900'
      : 'rgba(31, 31, 35, 0.50)';

  const handleKeyDown = (e: React.KeyboardEvent, label?: string) => {
    if (e.key === 'Enter') {
      if (ogChildItems.includes(label)) {
        push(routePath);
      }
      // SHAM-2570
      if (item.label === LeftSideMenuLabels.ShopCategories) {
        e.preventDefault();
        onClick();
      } else {
        e.preventDefault();
        document.getElementById(`link-${item.label}`)?.click();
      }
    }
  };

  const handleItemClick = (label: string) => {
    if (ogChildItems.includes(label)) {
      push(routePath);
      megaDrawer.onClose();
      return;
    }
    onClick();
    // TIMEOUT TO LOSE THE FOCUS STATE
    setTimeout(() => {
      if (item.label !== LeftSideMenuLabels.OrderGuides) {
        (document.activeElement as HTMLElement)?.blur();
      }
    }, 1000);
  };

  // SHAM-2570
  return (
    <ListItem
      px={10}
      py={2.5}
      h={item.label === LeftSideMenuLabels.ShopCategories ? '12' : '41px'}
      tabIndex={0}
      display="flex"
      onClick={() => handleItemClick(item?.label)}
      onKeyDown={(e) => handleKeyDown(e, item?.label)}
      alignItems="center"
      color="neutral.200"
      backgroundColor={backgroundColor}
      fontWeight={LeftSideMenuLabels.ShopCategories ? 700 : {}}
      fontSize={LeftSideMenuLabels.ShopCategories ? 'sm' : {}}
      _hover={{
        color: item.label === LeftSideMenuLabels.ShopCategories ? 'primary.500' : 'white',
        cursor: 'pointer',
        bg: { md: item.label === LeftSideMenuLabels.ShopCategories ? backgroundColor : 'greenGradient' },
      }}
      style={{ borderImage: 'linear-gradient(to bottom, #12875E 0%, #2DA343 100%) 1 stretch' }}
      borderRight={
        (isSelected(item.link.link) && !excludedShopCategoriesLabel.includes(item.label as LeftSideMenuLabels)) ||
        (ogChildItemSelected && isOrderGuideSelected)
          ? '4px'
          : 0
      }
      _focusWithin={{
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '-2px',
        boxShadow: 'none',
        borderRadius: '2px',
      }}
    >
      {/* SHAM-2570 */}
      {item?.label !== LeftSideMenuLabels.ShopCategories && !ogChildItems.includes(item.label) ? (
        <Box position="absolute" left={0} w={isMobileDrawer ? '339px' : '250px'} h="41px">
          <NextLink href={routePath} passHref tabIndex={-1}>
            <ChakraLink
              px={10}
              as="a"
              id={`link-${item.label}`}
              display="flex"
              w="full"
              h="full"
              alignItems="center"
              style={{ textDecoration: 'none' }}
            >
              {item.label}
            </ChakraLink>
          </NextLink>
        </Box>
      ) : (
        <>
          {item.label}
          {/* SHAM-2570 */}
          {item.label === LeftSideMenuLabels.ShopCategories && (
            <Icon ml={2} w={4} h={4} as={CaretRight} aria-label={formatMessage({ id: 'caret.right.icon.label' })} />
          )}
        </>
      )}
    </ListItem>
  );
};
